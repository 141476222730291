import React, { forwardRef, useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Slide,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import AdminPanelSettingsRoundedIcon from "@mui/icons-material/AdminPanelSettingsRounded";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const allUserRolesURL = `${process.env.REACT_APP_BACKEND_URL}api/v1/admin/getAllUserRoles`;
const updateUserRolesURL = `${process.env.REACT_APP_BACKEND_URL}api/v1/admin/updateUserRole`;

function AdminPanel() {
  const navigate = useNavigate();
  let jwtToken = { Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}` };
  const [open, setOpen] = useState(false);
  const [newRole, setNewRole] = useState("");
  const [roleData, setRoleData] = useState([
    {
      role_name: "None",
      role_code: "",
    },
  ]);
  const [newEmail, setNewEmail] = useState("");
  const [userFacility, setUserFacility] = useState("");
  const [loading, setLoading] = useState(false);
  const [ResponseStatus, setResponseStatus] = useState({
    flag: false,
    status: "",
    message: "",
  });

  useEffect(() => {
    const getAllUserRole = async () => {
      const headers = jwtToken;
      await axios
        .get(allUserRolesURL, {
          withCredentials: false,
          headers,
        })
        .then((response) => {
          if (response.status === 200 && response.data.response_message === "Success") {
            console.log(response);
            let base_Role = {
              role_name: "None",
              role_code: "",
            };
            const transformedData = response.data.response_data.map((item) => ({
              role_name: item.user_role,
              role_code: item.user_role_code,
            }));
            // transformedData.unshift(base_Role);
            console.log(transformedData);

            // Set the transformed data to the state
            setRoleData(transformedData);
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.code === "ERR_NETWORK" || err.response.status === 500) {
            // setErrorAlert(true);
            return;
          }
          if (err.response.status === 401) {
            sessionStorage.removeItem("jwtToken");
            sessionStorage.setItem("invalid_jwtToken", "invalid");
            navigate("/login");
            return;
          }
          if (err.response.status === 400) {
            console.log(err);
            return;
          }
        });
    };

    getAllUserRole();
  }, [open]);

  const handleNewRole = (event) => {
    console.log(event.target.value);
    setNewRole(event.target.value);
  };

  const handleNewEmail = (event) => {
    setNewEmail(event.target.value);
  };

  const handleUserFacility = (event) => {
    setUserFacility(event.target.value);
  };

  const updateAccountDetails = async () => {
    setLoading(true);
    const headers = jwtToken;
    const data = {
      user_email: newEmail,
      user_role_code: newRole,
      user_facility: userFacility,
    };
    await axios
      .post(updateUserRolesURL, data, {
        withCredentials: false,
        headers,
      })
      .then((response) => {
        if (response.data.response_message === "SUCCESS" && response.status === 200) {
          setLoading(false);
          setNewEmail("");
          setNewRole("");
          setUserFacility("");
          setResponseStatus({
            flag: true,
            status: "success",
            message: "Account updated",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.code === "ERR_NETWORK" || err.response.status === 500) {
          // setErrorAlert(true);
          return;
        }
        if (err.response.status === 401) {
          sessionStorage.removeItem("jwtToken");
          sessionStorage.setItem("invalid_jwtToken", "invalid");
          navigate("/login");
          return;
        }
        if (err.response.status === 400) {
          console.log(err);
          if (err.response.data.response_message === "ERROR: User does not exist") {
            setLoading(false);
            setResponseStatus({
              flag: true,
              status: "error",
              message: "User does not exist",
            });
            return;
          }
          if (err.response.data.response_message === "ERROR: Missing facility/hub for role Hub manager") {
            setLoading(false);
            setResponseStatus({
              flag: true,
              status: "error",
              message: "Missing User Facility",
            });
            return;
          }
        }
      });
    console.log("confirm");
  };

  // remove this after api integrated
  useEffect(() => {
    const timer = setInterval(() => {
      // Decrease the countdown value by 1 second
      // setLoading(false);
      setResponseStatus({
        flag: false,
        status: "",
        message: "",
      });
    }, 5000);

    // Clean up the timer when the component unmounts
    return () => clearInterval(timer);
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Box>
      <Tooltip title="Admin panel">
        <IconButton
          sx={{
            color: "#fff",
            ":hover": {
              background: "rgba(255, 255, 255, 0.47)",
            },
          }}
          onClick={() => {
            handleClickOpen();
            console.log("Admin panel");
          }}>
          <AdminPanelSettingsRoundedIcon />
        </IconButton>
      </Tooltip>
      {/* CloseIcon */}
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        disableEscapeKeyDown={true}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description">
        <DialogTitle sx={{ position: "relative" }}>
          {"Settings"}
          <DialogActions sx={{ position: "absolute", top: "0px", right: "0px" }}>
            <Tooltip title="Close">
              <IconButton
                sx={{
                  color: "#000000",
                }}
                onClick={() => {
                  handleClose();
                }}>
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </DialogActions>
        </DialogTitle>
        <DialogContent sx={{ height: "60vh" }}>
          <DialogContentText id="alert-dialog-slide-description">
            <Box sx={{ width: "fit-content", py: 1, px: 2, border: "1px solid #7d7d7d", borderRadius: "5px" }}>
              <Stack direction="row" gap={2}>
                <Typography sx={{ color: "#000000" }}>Manage Roles</Typography>
                {ResponseStatus.flag === true && (
                  <Typography sx={{ color: ResponseStatus.status === "success" ? "#00BA34" : "#E92C2C" }}>
                    {ResponseStatus.status === "success" ? (
                      <Stack gap={0.5} direction="row" justifyContent="center" alignItems="center">
                        {ResponseStatus.message} <CheckCircleOutlinedIcon fontSize="small" />
                      </Stack>
                    ) : (
                      <Stack gap={0.5} direction="row" justifyContent="center" alignItems="center">
                        {ResponseStatus.message} <ErrorOutlineOutlinedIcon fontSize="small" />
                      </Stack>
                    )}
                  </Typography>
                )}
              </Stack>
              <Stack gap={1} sx={{ py: 1 }}>
                <Stack direction="row" gap={2}>
                  <TextField
                    sx={{ fontWeight: 600, fontSize: 16, width: "20rem" }}
                    id="accountEmailAddress"
                    name="accountEmailAddress"
                    label="User Email"
                    size="small"
                    fullWidth
                    value={newEmail}
                    onChange={handleNewEmail}></TextField>
                  <FormControl sx={{ fontWeight: 300, fontSize: 13, width: "12rem" }} size="small">
                    <InputLabel id="Account-Role">Role</InputLabel>
                    <Select labelId="Account-Role" id="demo-simple-select" value={newRole} label="Role" onChange={handleNewRole}>
                      {roleData.map((item, index) => {
                        return <MenuItem value={item.role_code}>{item.role_name}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                </Stack>
                {newRole === "H" && (
                  <TextField
                    sx={{ fontWeight: 600, fontSize: 16, width: "100%" }}
                    id="userFacilityData"
                    name="userFacilityData"
                    label="User Facility"
                    size="small"
                    fullWidth
                    required
                    value={userFacility}
                    onChange={handleUserFacility}></TextField>
                )}
                <LoadingButton
                  loading={loading}
                  variant="contained"
                  sx={{ backgroundColor: "#0093fb", borderRadius: "10px", textTransform: "none", width: "80px" }}
                  onClick={updateAccountDetails}>
                  {" "}
                  Confirm
                </LoadingButton>
              </Stack>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default AdminPanel;

import { Avatar, Box, Button, IconButton, InputAdornment, Stack, TextField, Tooltip, Typography } from "@mui/material";

import "./message.css";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TypeAnimation } from "react-type-animation";
import axios from "axios";
import product_logo from "../../assets/dataOracle_logo.svg";
import ShareToEmailDialog from "../shareToEmailDialog";
import ReportMessageDialog from "../reportMessageDialog";

// mui import
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import SendIcon from "@mui/icons-material/Send";
import CircularProgress from "@mui/material/CircularProgress";
import ThumbUpRoundedIcon from "@mui/icons-material/ThumbUpRounded";
import ThumbDownRoundedIcon from "@mui/icons-material/ThumbDownRounded";
import AttachEmailOutlinedIcon from "@mui/icons-material/AttachEmailOutlined";
import ReportProblemRoundedIcon from "@mui/icons-material/ReportProblemRounded";

const getRequestAccess = `${process.env.REACT_APP_BACKEND_URL}api/v1/user/requestAccess`;

export default function Message(props) {
  // console.log(props);
  const navigate = useNavigate();
  let jwtToken = { Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}` };
  const CURSOR_CLASS_NAME = "custom-type-animation-cursor";
  const inputRef = useRef();
  const [isStackVisible, setStackVisible] = useState(true);
  const [graphMessageInput, setGraphMessageInput] = useState("");
  // const [graphId, setGraphId] = useState(0);
  const [loading, setLoading] = useState(false);
  let user = sessionStorage.getItem("username");
  const [reactionStatus, setReactionStatus] = useState(props.reaction);
  let access = props.content.includes("Not enough access.");

  const [shareToEmailOpen, setShareToEmailOpen] = useState(false);
  const [reportMessageOpen, setReportMessageOpen] = useState(false);
  const [typingStatus, setTypingStatus] = useState("Initializing");

  const [messageReportedFlag, setMessageReportedFlag] = useState(props.reported);
  const [messageReported, setMessageReported] = useState(props.reportedMessage);
  useEffect(() => {
    if (props.typing === false && props.image === true && props.graphID !== 0) {
      setLoading(false);
    }
  }, [props]);

  const handleGraphQuery = (e) => {
    // e.preventDefault();
    // setGraphId(props.graphID);
    props.graphQueryDetails({ graphMSG: graphMessageInput, graphID: props.graphID });
    setGraphMessageInput("");
    setLoading(true);
  };

  const handleKeyDown = (event) => {
    //<------use enter key to submit form
    if (event.which === 13) {
      // inputRef.current.getElementsByTagName("textarea")[0].style.height = "auto";
      // setGraphId(props.graphID);
      props.graphQueryDetails({ graphMSG: graphMessageInput, graphID: props.graphID });
      setGraphMessageInput("");
      setLoading(true);
      event.preventDefault();
    }
  };

  const handleBotResponseReaction = (event) => {
    // console.log(reactionStatus, event, props.reaction,props.messageID);
    if (reactionStatus === true && event === false) {
      props.botResponseReaction({ msgID: props.messageID, reaction: event });
      setReactionStatus(event);
      return;
    }
    if (reactionStatus === false && event === true) {
      props.botResponseReaction({ msgID: props.messageID, reaction: event });
      setReactionStatus(event);
      return;
    }
    if (reactionStatus === true || reactionStatus === false) {
      props.botResponseReaction({ msgID: props.messageID, reaction: null });
      setReactionStatus(null);
      return;
    } else {
      props.botResponseReaction({ msgID: props.messageID, reaction: event });
      setReactionStatus(event);
    }
  };

  const handleClick = async () => {
    const headers = jwtToken;
    await axios
      .get(getRequestAccess, {
        withCredentials: false,
        headers,
      })
      .then((response) => {
        if (response.status === 200 && response.data.response_message === "SUCCESS") {
          // console.log(response);
          props.requestAccess(true);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.code === "ERR_NETWORK" || err.response.status === 500) {
          // setErrorAlert(true);
          return;
        }
        if (err.response.status === 401) {
          sessionStorage.removeItem("jwtToken");
          sessionStorage.setItem("invalid_jwtToken", "invalid");
          navigate("/login");
          return;
        }
        if (err.response.status === 400) {
          console.log(err);
          return;
        }
      });
  };

  const handleShareButton = () => {
    setShareToEmailOpen(!shareToEmailOpen);
  };
  const handleReportButton = (data) => {
    // console.log(data);
    if (data !== undefined) {
      setMessageReportedFlag(data.reported_flag);
      setMessageReported(data.reported_message);
    }
    setReportMessageOpen(!reportMessageOpen);
  };

  const CustomTitle = () => {
    return (
      <Box sx={{ maxWidth: "300px" }}>
        <h3>Reported Reason: </h3>
        <p>{messageReported}</p>
      </Box>
    );
  };

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          flexFlow: "row",
          justifyContent: props.isUser ? "right" : "left",
          // backgroundColor: props.isUser ? "#0093fb" : "#ffffff",
          padding: "14px 100px 30px 100px",
        }}>
        <Box sx={{ position: "relative" }}>
          {props.isUser && (
            <Avatar
              sx={{
                color: "#0093fb",
                bgcolor: "#ffffff",
                borderRadius: "50%",
                width: 40,
                height: 40,
                lineHeight: "40px",
                position: "absolute",
                left: "-20px",
                top: "-14px",
              }}>
              <h6>{user}</h6>
            </Avatar>
          )}
          {!props.isUser && (
            <Avatar
              sx={{
                bgcolor: "#0093fb",
                borderRadius: "50%",
                width: 40,
                height: 40,
                lineHeight: "40px",
                position: "absolute",
                left: "-20px",
                top: "-14px",
              }}>
              <img src={product_logo} alt="GenInsight.ai" width={32} />
            </Avatar>
          )}

          {!props.isUser &&
            props.content !== "loading_message" &&
            (typingStatus === "Done Typing" || props.image || !props.typing) && (
              <Box
                sx={{
                  position: "absolute",
                  bottom: "0px",
                  right: "-30px",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                }}>
                <Tooltip title={messageReported === null ? "Report this message" : <CustomTitle />} placement="right">
                  <IconButton
                    sx={{
                      color: messageReportedFlag === true ? "#E92C2C" : "#ffffff",
                      backgroundColor: reactionStatus === false && "#ffffff",
                      ":hover": {
                        color: "#E92C2C",
                        backgroundColor: "#ffffff",
                      },
                    }}
                    onClick={() => {
                      // console.log("Message reported", props.messageID);
                      if (props.reported === true || messageReportedFlag === true) {
                        return;
                      } else {
                        handleReportButton();
                      }
                    }}
                    type="submit">
                    <ReportProblemRoundedIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          <ReportMessageDialog open={reportMessageOpen} onClose={handleReportButton} message_id={props.messageID} />
          <Typography
            gutterBottom
            variant="body2"
            component="div"
            sx={{
              mr: 1.5,
              maxWidth: "40rem",
              width: "fit-content",
              backgroundColor: props.isUser ? "#0093fb" : "#ffffff",
              color: props.isUser ? "#ffffff" : "#000000",
              float: "right",
              overflow: "hidden",
              fontSize: "1rem",
              borderRadius: "20px",
            }}>
            {props.isUser ? (
              <Box sx={{ padding: "20px" }}>{props.content}</Box>
            ) : props.typing ? (
              <Box
                sx={{
                  padding: "20px",
                  position: "realtive",
                  "&:hover .stack": {
                    display: "flex",
                  },
                }}
                // onMouseEnter={() => setStackVisible(true)}
                // onMouseLeave={() => setStackVisible(false)}
              >
                <TypeAnimation
                  sequence={[
                    // Same substring at the start will only be typed out once, initially
                    `${props.content}`,
                    (el) => el.classList.remove(CURSOR_CLASS_NAME), // A reference to the element gets passed as the first argument of a callback function
                    (el) => {
                      el.innerHTML += " "; // Add a space before the link
                      if (access === true) {
                        const link = document.createElement("span");
                        link.style.color = "blue";
                        link.style.textDecoration = "underline";
                        link.style.cursor = "pointer";
                        link.innerText = "\n Request access.";
                        link.addEventListener("click", handleClick);
                        el.appendChild(link);
                      }
                    },
                    () => {
                      setTypingStatus("Done Typing");
                    },
                  ]}
                  style={{ whiteSpace: "pre-line" }}
                  wrapper="span"
                  speed={90}
                  repeat={0}
                  cursor={false}
                  className={CURSOR_CLASS_NAME}
                />
                {typingStatus === "Done Typing" && (
                  <Stack
                    direction="row"
                    justifyContent="flex-end"
                    className={isStackVisible ? "stack" : ""}
                    sx={{
                      display: reactionStatus === true || reactionStatus === false || isStackVisible ? "flex" : "none",
                      position: "absolute",
                      bottom: "-24px",
                      right: "30px",
                      width: "100%",
                    }}>
                    <Tooltip title="Like">
                      <IconButton
                        className={isStackVisible ? "stack" : ""}
                        sx={{
                          display: reactionStatus === false && "none",
                          color: reactionStatus === true ? "#00BA34" : "#ffffff",
                          border: reactionStatus === true && "1px solid #00BA34",
                          backgroundColor: reactionStatus === true && "#ffffff",
                          ":hover": {
                            color: "#00BA34",
                            backgroundColor: reactionStatus === true && "#ffffff",
                          },
                        }}
                        onClick={() => {
                          handleBotResponseReaction(true);
                        }}
                        type="submit">
                        <ThumbUpRoundedIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Dislike">
                      <IconButton
                        className={isStackVisible ? "stack" : ""}
                        sx={{
                          display: reactionStatus === true && "none",
                          color: reactionStatus === false ? "#E92C2C" : "#ffffff",
                          border: reactionStatus === false && "1px solid #E92C2C",
                          backgroundColor: reactionStatus === false && "#ffffff",
                          ":hover": {
                            color: "#E92C2C",
                            backgroundColor: reactionStatus === false && "#ffffff",
                          },
                        }}
                        onClick={() => {
                          handleBotResponseReaction(false);
                        }}
                        type="submit">
                        <ThumbDownRoundedIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                )}
              </Box>
            ) : props.content === "loading_message" ? (
              <Box
                sx={{
                  padding: "20px",
                }}>
                <div className="loader3">
                  <div className="circle1"></div>
                  <div className="circle1"></div>
                  <div className="circle1"></div>
                  <div className="circle1"></div>
                  <div className="circle1"></div>
                </div>
              </Box>
            ) : props.image ? (
              <Box
                sx={{
                  padding: "20px",
                  position: "realtive",
                  "&:hover .stack": {
                    display: "flex",
                  },
                }}
                // onMouseEnter={() => setStackVisible(true)}
                // onMouseLeave={() => setStackVisible(false)}
              >
                <Stack rowGap={2}>
                  <img src={props.content} alt={props.content} style={{ height: "hug-content", width: "hug-content" }}></img>
                  <Stack direction="row" columnGap={2}>
                    <Tooltip title="Download graph">
                      <a
                        href={props.content}
                        download
                        className="graph-download-button"
                        style={{ pointerEvents: loading === true ? "none" : "" }}>
                        <FileDownloadOutlinedIcon sx={{ fontSize: "16px", color: "inherit" }} />
                        Download Graph
                      </a>
                    </Tooltip>
                    <Tooltip title="Share via Email">
                      <Button
                        variant="outlined"
                        size="small"
                        sx={{
                          pointerEvents: loading === true ? "none" : "",
                          width: "70px",
                          display: "flex",
                          justifyContent: "center",
                          gap: "6px",
                          border: "2px solid #0093fb",
                          borderRadius: "10px",
                          color: "#0093fb",
                          fontSize: "12px",
                          textTransform: "none",
                          ":hover": {
                            color: "#ffffff",
                            backgroundColor: "#0093fb",
                          },
                        }}
                        onClick={() => {
                          handleShareButton();
                        }}>
                        <AttachEmailOutlinedIcon sx={{ fontSize: "16px", color: "inherit" }} />
                        Share
                      </Button>
                    </Tooltip>
                    <ShareToEmailDialog open={shareToEmailOpen} onClose={handleShareButton} message_id={props.graphID} />
                    <TextField
                      variant="outlined"
                      autoComplete="off"
                      multiline
                      maxRows={2}
                      ref={inputRef}
                      onKeyDown={handleKeyDown}
                      value={graphMessageInput}
                      onChange={(event) => setGraphMessageInput(event.target.value)}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          padding: "4px 8px",
                        },
                        width: "60%",
                      }}
                      disabled={loading === true ? true : false}
                      placeholder="User Input"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {loading === false ? (
                              <IconButton
                                size="small"
                                variant="contained"
                                onClick={() => {
                                  handleGraphQuery();
                                }}
                                type="submit"
                                disabled={graphMessageInput === "" ? true : false}>
                                <Tooltip title="Send Graph Message">
                                  <SendIcon sx={{ fontSize: "15px", color: "#0093fb" }} />
                                </Tooltip>
                              </IconButton>
                            ) : (
                              <CircularProgress sx={{ color: "#0093fb" }} size={20} />
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Stack>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  className={isStackVisible ? "stack" : ""}
                  sx={{
                    display: reactionStatus === true || reactionStatus === false || isStackVisible ? "flex" : "none",
                    position: "absolute",
                    bottom: "-24px",
                    right: "30px",
                    width: "100%",
                  }}>
                  <Tooltip title="Like">
                    <IconButton
                      className={isStackVisible ? "stack" : ""}
                      sx={{
                        display: reactionStatus === false && "none",
                        color: reactionStatus === true ? "#00BA34" : "#ffffff",
                        border: reactionStatus === true && "1px solid #00BA34",
                        backgroundColor: reactionStatus === true && "#ffffff",
                        ":hover": {
                          color: "#00BA34",
                          backgroundColor: reactionStatus === true && "#ffffff",
                        },
                      }}
                      onClick={() => {
                        handleBotResponseReaction(true);
                      }}
                      type="submit">
                      <ThumbUpRoundedIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Dislike">
                    <IconButton
                      className={isStackVisible ? "stack" : ""}
                      sx={{
                        display: reactionStatus === true && "none",
                        color: reactionStatus === false ? "#E92C2C" : "#ffffff",
                        border: reactionStatus === false && "1px solid #E92C2C",
                        backgroundColor: reactionStatus === false && "#ffffff",
                        ":hover": {
                          color: "#E92C2C",
                          backgroundColor: reactionStatus === false && "#ffffff",
                        },
                      }}
                      onClick={() => {
                        handleBotResponseReaction(false);
                      }}
                      type="submit">
                      <ThumbDownRoundedIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </Box>
            ) : (
              <Box
                sx={{
                  padding: "20px",
                  position: "realtive",
                  "&:hover .stack": {
                    display: "flex",
                  },
                }}
                // onMouseEnter={() => setStackVisible(true)}
                // onMouseLeave={() => setStackVisible(false)}
              >
                {props.content.split("\n").map((line, index) => (
                  <span key={index}>
                    {line} <br />
                  </span>
                ))}
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  className={isStackVisible ? "stack" : ""}
                  sx={{
                    display: reactionStatus === true || reactionStatus === false || isStackVisible ? "flex" : "none",
                    position: "absolute",
                    bottom: "-24px",
                    right: "30px",
                    width: "100%",
                  }}>
                  <Tooltip title="Like">
                    <IconButton
                      className={isStackVisible ? "stack" : ""}
                      sx={{
                        display: reactionStatus === false && "none",
                        color: reactionStatus === true ? "#00BA34" : "#ffffff",
                        border: reactionStatus === true && "1px solid #00BA34",
                        backgroundColor: reactionStatus === true && "#ffffff",
                        ":hover": {
                          color: "#00BA34",
                          backgroundColor: reactionStatus === true && "#ffffff",
                        },
                      }}
                      onClick={() => {
                        handleBotResponseReaction(true);
                      }}
                      type="submit">
                      <ThumbUpRoundedIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Dislike">
                    <IconButton
                      className={isStackVisible ? "stack" : ""}
                      sx={{
                        display: reactionStatus === true && "none",
                        color: reactionStatus === false ? "#E92C2C" : "#ffffff",
                        border: reactionStatus === false && "1px solid #E92C2C",
                        backgroundColor: reactionStatus === false && "#ffffff",
                        ":hover": {
                          color: "#E92C2C",
                          backgroundColor: reactionStatus === false && "#ffffff",
                        },
                      }}
                      onClick={() => {
                        handleBotResponseReaction(false);
                      }}
                      type="submit">
                      <ThumbDownRoundedIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </Box>
            )}
          </Typography>
          {/* {props.image && <img src={props.image} alt="Bot response" style={{ width: "100%" }} />}
          {!props.isUser && props.choices && (
            <Box>
              {props.choices.map((choice, index) => (
                <Chip key={index} label={choice} onClick={() => props.handleChoice(choice)} sx={{ mr: 0.5, mb: 0.5 }} />
              ))}
            </Box>
          )} */}
        </Box>
      </Box>
    </div>
  );
}

import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "../src/pages/access/login";
import EntraLogin from "./pages/access/entraLogin";
import EntraPasslessLogin from "./pages/access/entraPasslessLogin";
import Signup from "../src/pages/access/signup";
import Otp from "../src/pages/access/otp";
import Chat from "../src/pages/chat/chat";
import ForgetPassword from "../src/pages/access/forgetpassword";
import Auth from "./pages/access/Auth";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<EntraPasslessLogin />} />
        <Route path="/login" element={<EntraPasslessLogin />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/signup/otp" element={<Otp />} />
        <Route path="/forget-password" element={<ForgetPassword />} />
        <Route path="/chat" element={<Chat />} />
        <Route path="/auth/:jwt_token" element={<Auth />} />
      </Routes>
    </Router>
  );
}

export default App;

import React, { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";

// mui import
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";

// regex
const EMAIL_REGEX = /^[\w\.]+@([\w-]+\.)+[\w-]{2,4}$/;

const postShareGraphToEmail = `${process.env.REACT_APP_BACKEND_URL}api/v1/user/sharePlot`;

function ShareToEmailDialog(props) {
  const { onClose, open, message_id } = props;
  const navigate = useNavigate();
  let jwtToken = { Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}` };

  const [emailShareDetails, setEmailShareDetails] = useState({ topic: "", email: "" });

  const DialogFields = [
    { name: "Email", initialValues: "sendToEmail" },
    { name: "Topic", initialValues: "graphTopic" },
  ];

  const ShareToEmailSchema = Yup.object().shape({
    graphTopic: Yup.string().required("Topic is required"),
    sendToEmail: Yup.string().required("Email is required").matches(EMAIL_REGEX, "Invalid Email address"),
  });

  const EmailFormik = useFormik({
    initialValues: {
      sendToEmail: "",
      graphTopic: "",
    },
    onSubmit: async (values) => {
      let headers = jwtToken;
      let data = {
        message_id: message_id,
        topic: values.graphTopic,
        to_email: values.sendToEmail,
      };
      await axios
        .post(postShareGraphToEmail, data, {
          withCredentials: false,
          headers,
        })
        .then((response) => {
          console.log(response);
          if (response.data.response_message === "SUCCESS" && response.status === 200) {
            EmailFormik.resetForm({ values: EmailFormik.initialValues });
            handleClose();
          }
          // setShareToEmailOpen(false);
        })
        .catch((err) => {
          console.log(err);
          if (err.code === "ERR_NETWORK" || err.response.status === 500) {
            // setErrorAlert(true);
            return;
          }
          if (err.response.status === 401) {
            sessionStorage.removeItem("jwtToken");
            sessionStorage.setItem("invalid_jwtToken", "invalid");
            navigate("/login");
            return;
          }
          if (err.response.status === 400) {
            console.log(err);
            return;
          }
        });
    },
    // validationSchema: ShareToEmailSchema,
  });

  const handleClose = () => {
    onClose();
  };

  const handleSendtoEmail = async () => {
    let headers = jwtToken;
    let data = {
      message_id: props.graphID,
      topic: emailShareDetails.topic,
      to_email: emailShareDetails.email,
    };
    await axios
      .post(postShareGraphToEmail, data, {
        withCredentials: false,
        headers,
      })
      .then((response) => {
        console.log(response);
        // setShareToEmailOpen(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.code === "ERR_NETWORK" || err.response.status === 500) {
          // setErrorAlert(true);
          return;
        }
        if (err.response.status === 401) {
          sessionStorage.removeItem("jwtToken");
          sessionStorage.setItem("invalid_jwtToken", "invalid");
          navigate("/login");
          return;
        }
        if (err.response.status === 400) {
          console.log(err);
          return;
        }
      });
  };

  return (
    <Dialog onClose={handleClose} open={open} maxWidth="xs">
      <DialogTitle>Share via Email</DialogTitle>
      <form onSubmit={EmailFormik.handleSubmit}>
        <DialogContent>
          <DialogContentText>
            To share this Graph, please enter your email address & graph topic below. We will send it for you.
          </DialogContentText>
          {DialogFields.map((item, idx) => {
            return (
              <TextField
                margin="dense"
                variant="standard"
                size="small"
                fullWidth
                key={idx}
                id={`standard-${idx}`}
                name={`${item.initialValues}`}
                type={item.name.includes("Password") ? "password" : "text"}
                label={`${item.name}`}
                value={EmailFormik.values[item.initialValues]}
                onChange={EmailFormik.handleChange}
              />
            );
          })}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="small"
            sx={{ backgroundColor: "#0093fb", borderRadius: "10px", textTransform: "none" }}
            onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            size="small"
            type="submit"
            disabled={!EmailFormik.isValid}
            sx={{ backgroundColor: "#0093fb", borderRadius: "10px", textTransform: "none" }}
            // onClick={handleSendtoEmail}
          >
            Send
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default ShareToEmailDialog;

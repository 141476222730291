import "./chat.css";
import React, { useEffect, useState, createRef, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Message from "../../components/message/message";
import ErrorAlert from "../../components/errorAlert";
import SuccessAlert from "../../components/successAlert";
import corestrat_logo_white from "../../assets/cs_logo_white.svg";
import SideBar_icon from "../../assets/sidebar_icon.svg";
import product_logo from "../../assets/dataOracle_logo.svg";
import AdminPanel from "./adminPanel";

import {
  Avatar,
  Box,
  Card,
  CardContent,
  Drawer,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
  Typography,
  styled,
  Chip,
  Grid,
  Divider,
} from "@mui/material";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import SendIcon from "@mui/icons-material/Send";
import ArrowCircleDownOutlinedIcon from "@mui/icons-material/ArrowCircleDownOutlined";
import AddIcon from "@mui/icons-material/Add";
import LogoutIcon from "@mui/icons-material/Logout";

const createSessionQuery = `${process.env.REACT_APP_BACKEND_URL}api/v1/user/createSession`;
const userQuery = `${process.env.REACT_APP_BACKEND_URL}api/v1/agent/processQuery`;
const processGraphHistory = `${process.env.REACT_APP_BACKEND_URL}api/v1/agent/processGraphHistory`;
const graphQuery = `${process.env.REACT_APP_BACKEND_URL}api/v1/agent/graphProcessQuery`;
const sessionHistoryQuery = `${process.env.REACT_APP_BACKEND_URL}api/v1/user/getAllSession`;
const chatHistoryQuery = `${process.env.REACT_APP_BACKEND_URL}api/v1/user/getAllMessageDetails/`;
const downloadChatHistoryQuery = `${process.env.REACT_APP_BACKEND_URL}api/v1/user/downloadChatHistory/`;
const getUserDetailQuery = `${process.env.REACT_APP_BACKEND_URL}api/v1/user/getUser`;
const logoutUserQuery = `${process.env.REACT_APP_BACKEND_URL}api/v1/user/logout`;
const sendMessageFeedbackQuery = `${process.env.REACT_APP_BACKEND_URL}api/v1/user/sendMessageFeedback`;

const MonthsInAlphabets = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const drawerWidth = 300;
const timeout = 180000; // 3min timeout for userQuery & graphQuery

const sampleQuestions = [
  "What is the P&L for the latest week?",
  "Illustrate and analyze the trend in gross margin over the past four weeks.",
  "For the latest week, what is the gross margin breakdown by hub?",
  "Calculate the per-package gross margin for Yun Express over the last five weeks.",
];

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
  flexGrow: 1,
  position: "relative",
  height: "100vh",
  overflowX: "hidden",
  overflowY: "auto",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginRight: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  }),
}));

function Chat() {
  const navigate = useNavigate();

  const inputRef = useRef();
  const messagesListRef = createRef();
  const [open, setOpen] = useState(true);
  const [activeChat, setActiveChat] = useState();
  const [userName, setUserName] = useState(sessionStorage.getItem("username"));
  const [messageInput, setMessageInput] = useState("");
  const [messages, setMessages] = useState([]);
  const [errorAlert, setErrorAlert] = useState(false);
  const [errorMessageAlert, setErrorMessageAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successAlert, setSuccessAlert] = useState(false);
  const [sessionId, setSessionId] = useState(0);
  const [scrollAllTheWayDown, setScrollAllTheWayDown] = useState(false);
  const [showIcon, setShowIcon] = useState({ 0: false, 1: false, 2: false, 3: false });
  const [loading, setLoading] = useState(false);
  const [allSession, setAllSession] = useState([]);
  const [selectedChatHistoryOptions, setSelectedChatHistoryOptions] = useState("Today");
  const [textFieldClick, setTextFieldClick] = useState(false);
  const [isTyping, setIsTyping] = useState(false);

  let chatHistoryLabels = ["Today", "This Week", "Prior Chats"];
  let user = sessionStorage.getItem("username");
  let userRole = sessionStorage.getItem("role");
  let jwtToken = { Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}` };

  const handleIconAppear = (iconPosition, newValue) => {
    setShowIcon((prev) => {
      return { ...prev, [iconPosition]: newValue };
    });
  };

  const formatData = (dataArr) => {
    let tempObj = {};
    let dataForToday = {};
    let dataForThisWeek = {};
    let dataForPriorWeek = {};
    let currentDate = new Date();
    let todayDate = `${currentDate.getDate()}.${currentDate.getMonth() + 1}.${currentDate.getFullYear()}`;
    let thisWeek = [];
    for (let i = 0; i < 7; i++) {
      thisWeek.push(
        `${currentDate.getDate() - currentDate.getDay() + i}.${currentDate.getMonth() + 1}.${currentDate.getFullYear()}`
      );
    }

    dataArr.map((data, idx) => {
      let date = new Date(data.created_datetime + "Z");
      let month = MonthsInAlphabets[date.getMonth()];

      let data_date = `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;

      let tempData = {
        id: data.id,
        date_time: `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}, ${date.getHours()}:${date.getMinutes()}`,
        session_name: month in tempObj ? `Session ${tempObj[month].length + 1}` : "Session 1",
      };

      if (data_date === todayDate) {
        if (month in dataForToday) {
          dataForToday[month].push(tempData);
        } else {
          dataForToday[month] = [tempData];
        }
      }

      if (Object.keys(dataForToday).length !== 0) {
        tempObj["Today"] = [dataForToday];
      }

      thisWeek.map((data, idx) => {
        if (data_date == data) {
          if (month in dataForThisWeek) {
            dataForThisWeek[month].push(tempData);
          } else {
            dataForThisWeek[month] = [tempData];
          }
        }
      });

      if (Object.keys(dataForThisWeek).length !== 0) {
        tempObj["This Week"] = [dataForThisWeek];
      }

      if (month in dataForPriorWeek) {
        dataForPriorWeek[month].push(tempData);
      } else {
        dataForPriorWeek[month] = [tempData];
      }

      if (Object.keys(dataForPriorWeek).length !== 0) {
        tempObj["Prior Chats"] = [dataForPriorWeek];
      }

      if (month in tempObj) {
        tempObj[month].push(tempData);
      } else {
        tempObj[month] = [tempData];
      }

      if (dataArr.length - 1 === idx && "Today" in tempObj) {
        getChatHistoryQuery(data.id);
        setSessionId(data.id);
      }
    });
    setAllSession(tempObj);
    if ("Today" in tempObj) {
      let date = new Date();
      let month = MonthsInAlphabets[date.getMonth()];
      setActiveChat(tempObj["Today"][0][month][tempObj["Today"][0][month].length - 1].id);
    }
  };

  useEffect(() => {
    async function getUserQuery() {
      setErrorAlert(false);
      let headers = jwtToken;
      await axios
        .get(getUserDetailQuery, { withCredentials: false, headers })
        .then((response) => {
          if (response.data.response_message === "SUCCESS") {
            console.log(response);
            setUserName(response.data.response_data.username);
            let user_first_name = response.data.response_data.username.split(" ")[0];
            let user_last_name = response.data.response_data.username.split(" ")[1];
            let user_name = "";
            if (user_last_name) {
              user_name = `${user_first_name.slice(0, 1)} ${user_last_name.slice(0, 1)}`;
              sessionStorage.setItem("username", user_name.toUpperCase());
            } else {
              user_name = `${user_first_name.slice(0, 1)}`;
              sessionStorage.setItem("username", user_name.toUpperCase());
            }
            sessionStorage.setItem("role", response.data.response_data.user_role_code);
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.code === "ERR_NETWORK" || err.response.status === 500) {
            setErrorAlert(true);
            return;
          }
          if (err.response.status === 401) {
            sessionStorage.removeItem("jwtToken");
            sessionStorage.setItem("invalid_jwtToken", "invalid");
            navigate("/login");
            return;
          }
          if (err.response.status === 400 && err.response.data.response_message === "ERROR: Invalid Authentication") {
            sessionStorage.removeItem("jwtToken");
            sessionStorage.setItem("invalid_jwtToken", "invalid");
            navigate("/login");
            return;
          }
        });
    }
    async function getSessionHistoryQuery() {
      setErrorAlert(false);
      let headers = jwtToken;
      await axios
        .get(sessionHistoryQuery, { withCredentials: false, headers })
        .then((response) => {
          if (response.status === 200 && response.data.response_message === "SUCCESS") {
            formatData(response.data.response_data);
            return;
          }
        })
        .catch((err) => {
          // handle error status
          // set error message into useState (error display on session storage)
          console.log(err);
          if (err.code === "ERR_NETWORK" || err.response.status === 500) {
            setErrorAlert(true);
            return;
          }
          if (err.response.status === 401) {
            sessionStorage.removeItem("jwtToken");
            sessionStorage.setItem("invalid_jwtToken", "invalid");
            navigate("/login");
            return;
          }
          if (err.response.status === 400) {
            console.log(err);
            return;
          }
        });
    }
    getUserQuery();
    getSessionHistoryQuery();
  }, []);

  async function postCreateSession() {
    setErrorAlert(false);
    setMessageInput("");
    const data = {};
    const headers = jwtToken;
    let result = await axios
      .post(createSessionQuery, data, { withCredentials: false, headers, jwtToken })
      .then((response) => {
        if (response.status === 201 && response.data.response_message === "CREATED") {
          // console.log("postCreateSession ", response);
          setSelectedChatHistoryOptions("Today");
          const res_data = response.data.response_data;
          setActiveChat(res_data.id);
          setSessionId(res_data.id);

          let date = new Date(res_data.created_datetime + "Z");
          let month = MonthsInAlphabets[date.getMonth()];
          let tempData = {
            id: res_data.id,
            date_time: `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}, ${date.getHours()}:${date.getMinutes()}`,
            session_name: month in allSession ? `Session ${allSession[month].length + 1}` : `Session 1`,
          };
          let data2 = allSession;
          let dataForToday = {};
          if (month in dataForToday) {
            dataForToday[month].push(tempData);
          } else {
            dataForToday[month] = [tempData];
          }

          if ("Today" in data2) {
            data2["Today"][0][month].push(tempData);
          } else {
            data2["Today"] = [dataForToday];
          }

          if (month in data2) {
            data2[month].push(tempData);
          } else {
            data2[month] = [tempData];
          }

          setAllSession(data2);
          setMessages([]);
        }
        return response.data.response_data.id;
      })
      .catch((err) => {
        console.log(err);
        if (err.code === "ERR_NETWORK" || err.response.status === 500) {
          setErrorAlert(true);
          return;
        }
        if (err.response.status === 401) {
          sessionStorage.removeItem("jwtToken");
          sessionStorage.setItem("invalid_jwtToken", "invalid");
          navigate("/login");
          return;
        }
        if (err.response.status === 400) {
          console.log(err);
          return;
        }
      });
    return result;
  }

  const postUserQuery = async (content) => {
    setLoading(true);
    setErrorAlert(false);
    setErrorMessageAlert(false);
    let graph_message_data = "";
    let message_id = 0;
    const headers = jwtToken;
    let data = { session_id: sessionId, user_query: content };
    if (sessionId === 0) {
      let session_Id = await postCreateSession();
      data = { session_id: session_Id, user_query: content };
    }

    // add the message to the state
    let update_messages = [
      ...messages,
      {
        content: content,
        isUser: true,
      },
      {
        content: "loading_message",
        isUser: false,
      },
    ];
    setMessages(update_messages);

    await axios
      .post(userQuery, data, { withCredentials: false, headers, timeout })
      .then((response) => {
        // console.log(response);
        if (response.status === 200 && response.data.response_message === "BOT_RESPONSE") {
          // console.log("userQuery ", response);
          response.data.response_data.map((res_data, index) => {
            if (update_messages[update_messages.length - 1].content === "loading_message") {
              update_messages = update_messages.map((item, index) => {
                // if it is the last element, return a new object with the updated content
                if (index === update_messages.length - 1) {
                  if (res_data.message_type === "text") {
                    graph_message_data = res_data.message_data;
                    message_id = res_data.id;
                    return { ...item, content: res_data.message_data, messageID: res_data.id, typing: true };
                  } else {
                    if (res_data.message_status === "error") {
                      return item;
                    } else {
                      return {
                        ...item,
                        content: res_data.message_data,
                        typing: false,
                        isUser: false,
                        image: true,
                        graphID: res_data.id,
                        messageID: res_data.id,
                      };
                    }
                  }
                }
                // otherwise, return the original item
                return item;
              });
              setMessages(update_messages);
            } else {
              if (res_data.message_type === "text") {
                update_messages.push({ content: res_data.message_data, typing: true, isUser: false });
              }
              setMessages(update_messages);
            }
          });
        }
        if (response.status === 200) {
          let graphdata = { session_id: sessionId, message_data: graph_message_data };
          // add the message to the state
          let update_graph_messages = [
            ...messages,
            {
              content: content,
              isUser: true,
            },
            {
              content: graph_message_data,
              typing: true,
              isUser: false,
              messageID: message_id,
            },
            {
              content: "loading_message",
              isUser: false,
            },
          ];
          setMessages(update_graph_messages);

          axios
            .post(processGraphHistory, graphdata, { withCredentials: false, headers, timeout })
            .then((response) => {
              // console.log(response);
              if (
                response.status === 200 &&
                response.data.response_message === "BOT_RESPONSE" &&
                response.data.response_data.length !== 0
              ) {
                response.data.response_data.map((res_data, index) => {
                  // console.log(res_data);
                  if (update_graph_messages[update_graph_messages.length - 1].content === "loading_message") {
                    update_graph_messages = update_graph_messages.map((item, index) => {
                      // if it is the last element, return a new object with the updated content
                      if (index === update_graph_messages.length - 1) {
                        if (res_data.message_type === "graph") {
                          if (res_data.message_status === "success") {
                            // console.log("success ", res_data.message_data);
                            return {
                              ...item,
                              content: res_data.message_data,
                              typing: false,
                              isUser: false,
                              image: true,
                              graphID: res_data.id,
                              messageID: res_data.id,
                            };
                          }
                          if (res_data.message_status === "error") {
                            // console.log("error ", res_data.message_data);
                            return item;
                            // return {
                            //   ...item,
                            //   content: res_data.message_data,
                            //   typing: false,
                            //   isUser: false,
                            //   graphID: res_data.id,
                            //   messageID: res_data.id,
                            // };
                          }
                        }
                      }
                      // otherwise, return the original item
                      return item;
                    });
                    if (res_data.message_status === "error") {
                      update_graph_messages.pop();
                    }
                    setMessages(update_graph_messages);
                  }
                });
              } else if (
                response.status === 200 &&
                response.data.response_message === "BOT_RESPONSE" &&
                response.data.response_data.length === 0
              ) {
                if (update_graph_messages[update_graph_messages.length - 1].content === "loading_message") {
                  update_graph_messages.pop();
                  // console.log(update_graph_messages);
                  setMessages(update_graph_messages);
                }
              }
              setLoading(false);
            })
            .catch((err) => {
              if (err.name === "AxiosError" || err.code === "ERR_NETWORK") {
                // console.log(err);
                if (update_graph_messages[update_graph_messages.length - 1].content === "loading_message") {
                  update_graph_messages = update_graph_messages.map((item, index) => {
                    // if it is the last element, return a new object with the updated content
                    if (index === update_graph_messages.length - 1) {
                      if (err.name === "AxiosError") {
                        setErrorAlert(true);
                        return;
                        // graph_message_data = "Internal Server Error, Please Try again.";
                        // return { ...item, content: `Internal Server Error, Please Try again.`, typing: true, isUser: false };
                      }
                    }
                    // otherwise, return the original item
                    return item;
                  });
                  update_graph_messages.pop();
                  setMessages(update_graph_messages);
                }
              }
              setLoading(false);
            });
        }
      })
      .catch((err) => {
        // handle error status
        // set error message into useState (same with the above)
        console.log(err);
        if (err.response.status) {
          if (err.response.status === 401) {
            sessionStorage.removeItem("jwtToken");
            sessionStorage.setItem("invalid_jwtToken", "invalid");
            navigate("/login");
            return;
          }
          if (err.response.status === 400 || err.response.status === 403) {
            console.log(err);
            setErrorMessageAlert(true);
            setErrorMessage(err.response.data.response_message);
            if (update_messages[update_messages.length - 1].content === "loading_message") {
              update_messages.pop();
              setMessages(update_messages);
            }
            setLoading(false);
            // if (err.response.data.response_message.includes("Not enough access.")) {
            // }

            // if (update_messages[update_messages.length - 1].content === "loading_message") {
            //   update_messages = update_messages.map((item, index) => {
            //     if (index === update_messages.length - 1) {
            //       return { ...item, content: err.response.data.response_message, typing: true };
            //     }
            //     return item;
            //   });
            // }
            // setMessages(update_messages);
            return;
          }
        }

        if (err.name === "AxiosError" || err.code === "ERR_NETWORK") {
          // console.log(err.code);
          if (update_messages[update_messages.length - 1].content === "loading_message") {
            update_messages = update_messages.map((item, index) => {
              // if it is the last element, return a new object with the updated content
              if (index === update_messages.length - 1) {
                if (err.name === "AxiosError") {
                  setErrorAlert(true);
                  return;
                  // graph_message_data = "Internal Server Error, Please Try again.";
                  // return { ...item, content: `Internal Server Error, Please Try again.`, typing: true, isUser: false };
                }
              }
              // otherwise, return the original item
              return item;
            });
            update_messages.pop();
            setMessages(update_messages);
          }
        }
        setLoading(false);
      });
  };

  const postGraphQuery = async (graphProps) => {
    setErrorAlert(false);
    const headers = jwtToken;
    const data = { session_id: sessionId, message_id: graphProps["graphID"], user_query: graphProps["graphMSG"] };

    await axios
      .post(graphQuery, data, { withCredentials: false, headers, timeout })
      .then((response) => {
        if (response.status === 200 && response.data.response_message === "BOT_RESPONSE") {
          //console.log("postGraphQuery," response)
          response.data.response_data.map((res_data, index) => {
            let updated_graph = messages.map((data, index) => {
              if (data.graphID === res_data.id) {
                return {
                  ...data,
                  content: res_data.message_data,
                  typing: false,
                  isUser: false,
                  image: true,
                  graphID: res_data.id,
                  messageID: res_data.id,
                };
              }
              return data;
            });
            setMessages(updated_graph);
          });
        }
      })
      .catch((err) => {
        // handle error status
        // set error message into useState (same with the above)
        console.log(err);
        if (err.code === "ERR_NETWORK" || err.response.status === 500) {
          setErrorAlert(true);
          return;
        }
        if (err.response.status === 401) {
          sessionStorage.removeItem("jwtToken");
          sessionStorage.setItem("invalid_jwtToken", "invalid");
          navigate("/login");
          return;
        }
        if (err.response.status === 400) {
          console.log(err);
          return;
        }
      });
  };

  const getChatHistoryQuery = async (session_id) => {
    setErrorAlert(false);
    const headers = jwtToken;
    setMessageInput("");
    setMessages([]);
    setSessionId(session_id);
    await axios
      .get(`${chatHistoryQuery}${session_id}`, { withCredentials: false, headers })
      .then((response) => {
        if (response.status === 200 && response.data.response_message === "SUCCESS") {
          // console.log("chatHistoryQuery", response);
          response.data.response_data.map((res_data, index) => {
            // console.log(res_data);

            if (res_data.message_status === "error") {
              return;
            }
            setMessages((messages) => [
              ...messages,
              {
                content: res_data.message_status === "success" ? res_data.message_data : undefined,
                image: res_data.message_type === "graph" && res_data.message_status === "success" ? true : false,
                isUser: res_data.message_owner === "user" ? true : false,
                typing: false,
                graphID: res_data.message_type === "graph" && res_data.id,
                messageID: res_data.id,
                reaction: res_data.reaction,
                reported: res_data.reported_reason !== null ? true : false,
                reportedMessage: res_data.reported_reason,
              },
            ]);
          });
        }
      })
      .catch((err) => {
        // handle error status
        // set error message into useState (error display on session storage)
        console.log(err);
        if (err.code === "ERR_NETWORK" || err.response.status === 500) {
          setErrorAlert(true);
          return;
        }
        if (err.response.status === 401) {
          sessionStorage.removeItem("jwtToken");
          sessionStorage.setItem("invalid_jwtToken", "invalid");
          navigate("/login");
          return;
        }
        if (err.response.status === 400) {
          console.log(err);
          return;
        }
      });
  };

  const getDownloadChatHistoryQuery = async (session_id) => {
    setErrorAlert(false);
    const headers = jwtToken;
    const data = {};
    await axios
      .post(`${downloadChatHistoryQuery}${session_id}`, data, {
        withCredentials: false,
        headers,
      })
      .then((response) => {
        if (response.status === 201 && response.data.response_message === "SUCCESS") {
          // console.log("getDownloadChatHistoryQuery ", response);
          window.open(response.data.response_data.download_url, "_blank");
        }
      })
      .catch((err) => {
        // handle error status
        // set error message into useState (error message)
        console.log(err);
        if (err.code === "ERR_NETWORK" || err.response.status === 500) {
          setErrorAlert(true);
          return;
        }
        if (err.response.status === 401) {
          sessionStorage.removeItem("jwtToken");
          sessionStorage.setItem("invalid_jwtToken", "invalid");
          navigate("/login");
          return;
        }
        if (err.response.status === 400) {
          console.log(err);
          return;
        }
      });
  };

  const postLogoutQuery = async () => {
    setErrorAlert(false);
    const headers = jwtToken;
    const data = {};
    await axios
      .post(logoutUserQuery, data, { withCredentials: false, headers })
      .then((response) => {
        if (response.data.response_message === "Logged Out") {
          sessionStorage.clear();
          navigate("/login");
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.code === "ERR_NETWORK" || err.response.status === 500) {
          setErrorAlert(true);
          return;
        }
        if (err.response.status === 401) {
          sessionStorage.removeItem("jwtToken");
          sessionStorage.setItem("invalid_jwtToken", "invalid");
          navigate("/login");
          return;
        }
        if (err.response.status === 400) {
          console.log(err);
          return;
        }
      });
  };

  const postMessageReactions = async (reactionProps) => {
    // console.log(reactionProps);
    setErrorAlert(false);
    const headers = jwtToken;
    const data = { message_id: reactionProps["msgID"], reaction: reactionProps["reaction"] };

    await axios
      .post(sendMessageFeedbackQuery, data, { withCredentials: false, headers })
      .then((response) => {
        // console.log(response);
      })
      .catch((err) => {
        // handle error status
        // set error message into useState (error message)
        console.log(err);
        if (err.code === "ERR_NETWORK" || err.response.status === 500) {
          setErrorAlert(true);
          return;
        }
        if (err.response.status === 401) {
          sessionStorage.removeItem("jwtToken");
          sessionStorage.setItem("invalid_jwtToken", "invalid");
          navigate("/login");
          return;
        }
        if (err.response.status === 400) {
          console.log(err);
          return;
        }
      });
  };

  const handleRequestAccess = (accessProps) => {
    setErrorMessageAlert(false);
    setSuccessAlert(accessProps);
  };

  const handleSideBarStatus = () => {
    setOpen(!open);
  };

  const handleMainPromptSubmit = (event) => {
    event.preventDefault();

    postUserQuery(messageInput);
    setMessageInput("");
  };

  const handleKeyDown = (event) => {
    //<------use enter key to submit form
    if (event.which === 13) {
      // inputRef.current.getElementsByTagName("textarea")[0].style.height = "auto";
      postUserQuery(messageInput);
      setMessageInput("");
      event.preventDefault();
    }
  };

  const handleScrollAllTheWayDown = (event) => {
    if (messagesListRef.current.scrollTop !== null) {
      messagesListRef.current.scrollTop = messagesListRef.current.scrollHeight;
      setScrollAllTheWayDown(false);
    }
  };

  const handleScroll = () => {
    const scrollableDiv = messagesListRef.current;
    if (scrollableDiv !== null) {
      if (scrollableDiv.scrollTop === 0) {
        setScrollAllTheWayDown(false);
        return;
      }
      if (scrollableDiv.scrollTop < scrollableDiv.dataset.scrollTop) {
        setScrollAllTheWayDown(true);
      }
      scrollableDiv.dataset.scrollTop = scrollableDiv.scrollTop;

      if (
        [0, 1, 2, 3].includes(
          scrollableDiv.scrollHeight - Math.floor(scrollableDiv.dataset.scrollTop) - scrollableDiv.clientHeight
        )
      ) {
        setScrollAllTheWayDown(false);
        return;
      }
    }
  };

  useEffect(() => {
    const scrollableDiv = messagesListRef.current;
    // Add a scroll event listener to the div when the component mounts
    scrollableDiv.addEventListener("scroll", handleScroll);
    // console.log(scrollableDiv.clientHeight);
    return () => {
      // Remove the scroll event listener when the component unmounts
      scrollableDiv.removeEventListener("scroll", handleScroll);
    };
  }, [messagesListRef]);

  // so that message will be render after the previous responds
  useEffect(() => {
    // if (messagesListRef.current.scrollTop !== null) {
    //   messagesListRef.current.scrollTop = messagesListRef.current.scrollHeight;
    // }
    messagesListRef.current.scrollTop = messagesListRef.current.scrollHeight;
    // console.log(messagesListRef.current.scrollTop);
    // console.log(messagesListRef.current.scrollHeight);
  }, [messages]);

  const [suggestions, setSuggestions] = useState([]);

  const handleUserInputChange = (e) => {
    const value = e.target.value;
    // console.log(value);
    setMessageInput(value);

    if (value === "") {
      setSuggestions(sampleQuestions);
    }
    if (value) {
      setSuggestions([]);
      return;
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setMessageInput(suggestion);

    setSuggestions([]);
  };

  const handleOnClickUserInput = () => {
    if (messageInput !== "") {
      setSuggestions([]);
      return;
    }
    setSuggestions(sampleQuestions);
  };

  return (
    <Box
      sx={{
        display: "flex",
        position: "relative",
        background: "radial-gradient(102.31% 102.31% at 81.79% -2.31%, #0C1D49 0%, #0432B9 100%)",
        height: "100vh",
      }}>
      <Main open={open}>
        {errorAlert === true && <ErrorAlert />}
        {errorMessageAlert === true && (
          <ErrorAlert
            jwtToken={jwtToken}
            errorMessage={errorMessage}
            requestAccessError={errorMessageAlert}
            requestAccess={(accessProps) => handleRequestAccess(accessProps)}
          />
        )}
        {successAlert === true && <SuccessAlert status={"successful"} />}
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ position: "absolute", top: "10px", left: "20px" }}>
          <img src={product_logo} alt="Data Oracle logo" style={{ height: "46px" }}></img>
          <Typography
            sx={{
              color: "#ffffff",
              fontSize: "18px",
              fontWeight: "600",
              lineHeight: "19px",
              letterSpacing: "0em",
              textAlign: "center",
              cursor: "default",
            }}>
            GenInsight.ai
          </Typography>
        </Stack>
        <Box
          ref={messagesListRef}
          sx={{
            // width: "101rem",
            width: "100%",
            // backgroundColor: "red",
            marginTop: "55px",
            // marginLeft: open === false && "100px",
            height: suggestions.length !== 0 ? "calc(100% - 19rem)" : "calc(100% - 9rem)",
            // height: "calc(100% - 9rem)",
            overflow: "auto",
          }}
          onClick={() => {
            setSuggestions([]);
          }}>
          {messages.map((message, index) => (
            <Message
              key={index}
              content={message.content}
              image={message.image}
              isUser={message.isUser}
              choices={message.choices}
              typing={message.typing}
              graphID={message.graphID}
              messageID={message.messageID}
              reaction={message.reaction}
              reported={message.reported}
              reportedMessage={message.reportedMessage}
              handleChoice={postUserQuery}
              graphQueryDetails={(graphProps) => postGraphQuery(graphProps)}
              botResponseReaction={(reactionProps) => postMessageReactions(reactionProps)}
              requestAccess={(accessProps) => handleRequestAccess(accessProps)}
            />
          ))}
          {/* <div id="section2" style={{ height: "2.625rem" }}></div> */}
        </Box>

        {messages.length === 0 && (
          <div className="welcome-message">
            <Stack>
              <img src={product_logo} alt="Data Oracle logo" style={{ height: "200px" }}></img>
              <h1 style={{ width: "100%", textAlign: "center", color: "#FFFDFD87", cusor: "default" }}>
                {" "}
                Welcome! I am GenInsight.ai{" "}
              </h1>
            </Stack>
          </div>
        )}
        <Box
          component="form"
          sx={{
            position: "fixed",
            bottom: "0px",
            // width: open ? "calc(100% - 308px)" : "calc(100% - 9px)",
            // width: "100vw",
            width: "calc(100% - 308px)",
            height: messages.length === 0 || suggestions.length !== 0 ? "14.625rem" : "5.625rem",
            display: "flex",
            flexFlow: "row",
            justifyContent: "center",
            alignItems: "flex-end",
            gap: 3,
            mb: 3,
            // background: "linear-gradient(180deg, rgba(255, 255, 255, 0.8) 1.0%, #fff 20.00%)",
            background: "transparent",
            // background: "red",
          }}
          onClick={() => {
            if (suggestions.length !== 0) {
              setSuggestions([]);
              return;
            }
            if (suggestions.length === 0) {
              return;
            }
          }}>
          {messages.length !== 0 && (
            <>
              <IconButton
                sx={{
                  border: "2px solid #ffffff",
                  color: "#ffffff",
                  background: "rgba(255, 255, 255, 0.17)",
                  ":hover": {
                    background: "rgba(255, 255, 255, 0.47)",
                    border: "2px solid #ffffff",
                  },
                }}
                onClick={() => {
                  postCreateSession();
                  setShowIcon({ 0: false, 1: false, 2: false, 3: false });
                }}
                disabled={loading ? true : false}>
                <Tooltip title="New Chat">
                  <AddIcon />
                </Tooltip>
              </IconButton>
            </>
          )}
          <Stack sx={{ width: "42%" }}>
            {messages.length === 0 && (
              <Stack
                sx={{
                  border: "1px solid rgba(255, 255, 255, 0.40)",
                  background: "rgba(255, 255, 255, 0.10)",
                  borderRadius: "10px",
                  zIndex: 20,
                  margin: "0 auto",
                  width: "80%",
                  marginBottom: "10px",
                }}>
                {sampleQuestions.map((text, index) => {
                  return (
                    <Stack columnGap={2} key={index}>
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        className="sample_question_box"
                        onMouseEnter={() => handleIconAppear(index, true)}
                        onMouseLeave={() => handleIconAppear(index, false)}
                        onClick={() => {
                          postUserQuery(text);
                        }}>
                        <span style={{ fontSize: "12px" }}>{text}</span>
                        <Tooltip title="Send">
                          {showIcon[index] ? <SendIcon /> : <SendIcon className="sample_question_icon" />}
                        </Tooltip>
                      </Stack>
                    </Stack>
                  );
                })}
              </Stack>
            )}

            {messages.length !== 0 && suggestions.length !== 0 && (
              <Stack
                sx={{
                  border: suggestions.length !== 0 && "1px solid rgba(255, 255, 255, 0.40)",
                  background: "rgba(255, 255, 255, 0.10)",
                  borderRadius: "10px",
                  zIndex: 20,
                  margin: "0 auto",
                  width: "90%",
                  marginBottom: "10px",
                }}>
                {suggestions.map((suggestion, index) => {
                  return (
                    <Stack columnGap={2} key={index}>
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        className="sample_question_box"
                        // onMouseEnter={() => handleIconAppear(index, true)}
                        // onMouseLeave={() => handleIconAppear(index, false)}
                        onClick={() => handleSuggestionClick(suggestion)}>
                        <span style={{ fontSize: "12px" }}>{suggestion}</span>
                        <Tooltip title="Send">
                          {showIcon[index] ? <SendIcon /> : <SendIcon className="sample_question_icon" />}
                        </Tooltip>
                      </Stack>
                    </Stack>
                  );
                })}
              </Stack>
            )}

            <TextField
              className="custom-textfield"
              sx={{
                maxWidth: "50rem",
                width: "100%",
                color: "#ffffff",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "30px",
                  border: "2px solid #fff",
                  background: "rgba(255, 255, 255, 0.17)",
                  color: "#ffffff",
                },
                ":hover": { background: "rgba(255, 255, 255, 0.17)", borderRadius: "30px" },
              }}
              variant="outlined"
              autoComplete="off"
              size="small"
              ref={inputRef}
              onKeyDown={handleKeyDown}
              onClick={handleOnClickUserInput}
              // onFocus={handleTextFieldFocus}
              multiline
              maxRows={3}
              disabled={loading ? true : false}
              placeholder="Type your question"
              value={messageInput}
              onChange={handleUserInputChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      variant="contained"
                      onClick={handleMainPromptSubmit}
                      type="submit"
                      disabled={messageInput === "" ? true : false}>
                      <Tooltip title="Send Message">
                        <SendIcon className="send-message-icon" />
                      </Tooltip>
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
          {messages.length !== 0 && (
            <>
              <IconButton
                sx={{
                  border: "2px solid #ffffff",
                  color: "#ffffff",
                  background: "rgba(255, 255, 255, 0.17)",
                  ":hover": {
                    background: "rgba(255, 255, 255, 0.47)",
                    border: "2px solid #ffffff",
                  },
                }}
                onClick={() => {
                  getDownloadChatHistoryQuery(sessionId);
                }}
                // type="submit"
                disabled={loading ? true : false}>
                <Tooltip title="Download chat history">
                  <FileDownloadOutlinedIcon />
                </Tooltip>
              </IconButton>
            </>
          )}

          {scrollAllTheWayDown ? (
            <IconButton
              variant="contained"
              onClick={handleScrollAllTheWayDown}
              type="button"
              sx={{ position: "absolute", right: "10px", top: "-60px" }}>
              <ArrowCircleDownOutlinedIcon sx={{ color: "#ffffff" }} />
            </IconButton>
          ) : null}
        </Box>
      </Main>
      {open === false && (
        <Tooltip title="Open sidebar" placement="right">
          <IconButton
            onClick={handleSideBarStatus}
            sx={{
              height: "30px",
              width: "30px",
              borderRadius: "4px",
              backgroundColor: "rgba(255, 255, 255, 0.47)",
              border: "1px solid #ffffff",
              color: "#fff",
              position: "absolute",
              right: "20px",
              top: "96px",
              ":hover": {
                backgroundColor: "rgba(255, 255, 255, 0.27)",
              },
            }}>
            {/* {theme.direction === "ltr" ? <ChevronLeftIcon /> : <ChevronRightIcon />} */}
            <img src={SideBar_icon} alt="side bar icon" style={{ height: "14px" }} />
          </IconButton>
        </Tooltip>
      )}
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            backgroundColor: "transparent",
          },
        }}
        variant="persistent"
        anchor="right"
        open={open}>
        <Box sx={{ display: "flex" }}>
          <Card sx={{ margin: "0px 10px 30px 20px", background: "transparent", width: drawerWidth, boxShadow: "none" }}>
            <CardContent sx={{ display: "flex", gap: "5px", justifyContent: "flex-end" }}>
              <Stack direction="row" justifyContent="center" alignItems="center" columnGap={3}>
                {userRole === "A" && <AdminPanel />}
                <Stack direction="row" justifyContent="center" alignItems="center">
                  <Avatar
                    sx={{
                      mr: 1,
                      backgroundColor: "#0093fb",
                      color: "#ffffff",
                      borderRadius: "5px",
                      width: 32,
                      height: 32,
                      lineHeight: "32px",
                    }}>
                    <h6>{user}</h6>
                  </Avatar>
                  <Typography sx={{ color: "#ffffff" }}>{userName}</Typography>
                </Stack>
                <Tooltip title="Logout">
                  <IconButton
                    sx={{
                      border: "2px solid #ffffff",
                      color: "#ffffff",
                      background: "rgba(255, 255, 255, 0.17)",
                      ":hover": {
                        background: "rgba(255, 255, 255, 0.47)",
                        border: "2px solid #ffffff",
                      },
                    }}
                    onClick={() => {
                      postLogoutQuery();
                    }}
                    // disabled={loading ? true : false}
                  >
                    <LogoutIcon />
                  </IconButton>
                </Tooltip>
              </Stack>
            </CardContent>
            <Tooltip title="Close sidebar" placement="right">
              <IconButton
                onClick={handleSideBarStatus}
                sx={{
                  height: "30px",
                  width: "30px",
                  borderRadius: "4px",
                  backgroundColor: "rgba(255, 255, 255, 0.47)",
                  border: "1px solid #ffffff",
                  color: "#fff",
                  marginTop: "20px",
                  marginRight: "4px",
                  ":hover": {
                    backgroundColor: "rgba(255, 255, 255, 0.27)",
                  },
                }}>
                {/* {theme.direction === "ltr" ? <ChevronLeftIcon /> : <ChevronRightIcon />} */}
                <img src={SideBar_icon} alt="side bar icon" style={{ height: "14px" }} />
              </IconButton>
            </Tooltip>
          </Card>
        </Box>
        <Box>
          <Grid
            container
            spacing={1}
            sx={{
              flexShrink: 0,
              boxSizing: "border-box",
              marginLeft: "16px",
              width: "270px",
              borderRadius: "20px",
              background: "rgba(255, 255, 255, 0.10)",
              boxShadow: "7px 8px 14px 0px rgba(0, 0, 0, 0.15)",
            }}>
            <Grid
              item
              lg={12}
              sx={{
                display: "flex",
                height: "5rem",
                textAlign: "center",
                background: "#0093fb",
                borderRadius: "20px 20px 0px 0px",
                color: "#ffffff",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "normal",
                cursor: "default",
              }}
              justifyContent="center"
              alignItems="center">
              {" "}
              Chat history
            </Grid>

            {chatHistoryLabels.map((item, idx) => {
              return (
                <Grid item lg={12} key={idx} sx={{ display: "flex", justifyContent: "center" }}>
                  <Tooltip title={`${item}`} placement="right">
                    <Chip
                      label={`${item}`}
                      disabled={loading ? true : false}
                      sx={{
                        width: "200px",
                        color: "#fff",
                        backgroundColor: selectedChatHistoryOptions === item ? "#0093fb" : null,
                        border: selectedChatHistoryOptions !== item ? "2px solid #ffffff" : null,
                        borderRadius: "10px",
                        ":hover": {
                          border: "none",
                          backgroundColor: "#0093fb",
                        },
                      }}
                      onClick={() => {
                        setSelectedChatHistoryOptions(item);
                      }}
                    />
                  </Tooltip>
                </Grid>
              );
            })}

            <Grid item lg={12} sx={{ overflow: "auto", height: "calc(100vh - 27rem)", padding: "10px", position: "relative" }}>
              {selectedChatHistoryOptions in allSession && allSession !== null ? (
                allSession[selectedChatHistoryOptions].map((session, idx) => {
                  return (
                    <div key={idx}>
                      {Object.entries(session)
                        .reverse()
                        .map(([key, value]) => {
                          <Typography sx={{ color: "#ffffff" }}>{key}</Typography>;
                          let results = value
                            .slice()
                            .reverse()
                            .map((item, idx) => {
                              return (
                                <Box key={idx}>
                                  <Box
                                    className={
                                      activeChat === item["id"]
                                        ? "session_history_tab session_history_active "
                                        : loading
                                        ? "session_history_tab SHT_disabled"
                                        : "session_history_tab"
                                    }
                                    onClick={() => {
                                      setActiveChat(item["id"]);
                                      if (!(activeChat === item["id"])) {
                                        getChatHistoryQuery(item["id"]);
                                      }
                                    }}>
                                    <p>{item.session_name}</p>
                                    <p style={{ fontSize: "12px" }}>{item.date_time}</p>
                                  </Box>
                                  <Divider sx={{ color: "#FFFFFF33", border: "1px solid #FFFFFF33", margin: "10px 0px" }} />
                                </Box>
                              );
                            });
                          return (
                            <>
                              <Typography sx={{ color: "#ffffff" }}>{key}</Typography>;{results}
                            </>
                          );
                        })}
                    </div>
                  );
                })
              ) : (
                <Typography
                  variant="h5"
                  sx={{
                    color: "#ffffff",
                    width: "100%",
                    textAlign: "center",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}>
                  No Chat History
                </Typography>
              )}
            </Grid>
          </Grid>

          <Stack
            direction="row"
            gap={1}
            justifyContent="center"
            alignItems="center"
            sx={{ position: "absolute", bottom: "10px", left: "4rem", cursor: "default" }}>
            <Typography sx={{ color: "#ffffff" }}>Powered by </Typography>
            <img style={{ width: "100px" }} src={corestrat_logo_white} alt="Corestrat Logo"></img>
          </Stack>
        </Box>
      </Drawer>
    </Box>
  );
}

export default Chat;

import React, { useState } from "react";
import { useFormik } from "formik";

// mui import
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";

const postReportMessage = `${process.env.REACT_APP_BACKEND_URL}api/v1/user/reportMessage`;

function ReportMessageDialog(props) {
  const { onClose, open, message_id } = props;
  const navigate = useNavigate();
  let jwtToken = { Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}` };

  const [loading, setLoading] = useState(false);
  // const [reported, setReported] = useState(false);
  let reported = false;

  const DialogFields = [{ name: "Reason", initialValues: "messageReason" }];

  const ReportMessageFormik = useFormik({
    initialValues: {
      messageReason: "",
    },
    onSubmit: async (values) => {
      setLoading(true);
      let headers = jwtToken;
      let data = {
        message_id: message_id,
        reason: values.messageReason,
      };
      console.log(data);
      await axios
        .post(postReportMessage, data, {
          withCredentials: false,
          headers,
        })
        .then((response) => {
          console.log(response);
          if (response.data.response_message === "SUCCESS" && response.status === 200) {
            setLoading(false);
            // setReported(true);
            reported = true;
            handleClose();
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.code === "ERR_NETWORK" || err.response.status === 500) {
            // setErrorAlert(true);
            return;
          }
          if (err.response.status === 401) {
            sessionStorage.removeItem("jwtToken");
            sessionStorage.setItem("invalid_jwtToken", "invalid");
            navigate("/login");
            return;
          }
          if (err.response.status === 400) {
            console.log(err);
            return;
          }
        });
    },
    // validationSchema: ShareToEmailSchema,
  });

  const handleClose = () => {
    onClose({ reported_flag: reported, reported_message: ReportMessageFormik.values.messageReason });
    ReportMessageFormik.resetForm({ values: ReportMessageFormik.initialValues });
  };

  return (
    <Dialog onClose={handleClose} open={open} maxWidth="xs">
      <DialogTitle>Report this message</DialogTitle>
      <form onSubmit={ReportMessageFormik.handleSubmit}>
        <DialogContent>
          <DialogContentText>To report this Bot Response, please enter the reason below.</DialogContentText>
          {DialogFields.map((item, idx) => {
            return (
              <TextField
                margin="dense"
                variant="standard"
                size="small"
                fullWidth
                key={idx}
                id={`standard-${idx}`}
                name={`${item.initialValues}`}
                type={item.name.includes("Password") ? "password" : "text"}
                label={`${item.name} (Optional)`}
                value={ReportMessageFormik.values[item.initialValues]}
                onChange={ReportMessageFormik.handleChange}
              />
            );
          })}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="small"
            sx={{ backgroundColor: "#0093fb", borderRadius: "10px", textTransform: "none" }}
            onClick={handleClose}>
            Cancel
          </Button>
          <LoadingButton
            loading={loading}
            variant="contained"
            size="small"
            type="submit"
            disabled={!ReportMessageFormik.isValid}
            sx={{ backgroundColor: "#0093fb", borderRadius: "10px", textTransform: "none" }}
            // onClick={handleSendtoEmail}
          >
            Confirm
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default ReportMessageDialog;

import "./access.css";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";

import Corestrat_logo from "../../assets/Corestrat.svg";
import ACI_logo from "../../assets/ACI_logo.svg";
import ErrorAlert from "../../components/errorAlert";

import { Button, Grid, Stack, TextField, Typography, createTheme } from "@mui/material";

const loginApiURL = `${process.env.REACT_APP_BACKEND_URL}api/v1/user/login`;

const myTheme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          input: {
            "&:-webkit-autofill": {
              WebkitBoxShadow: "0 0 0 100px #E0D98C <<<<(Your color here) inset",
              WebkitTextFillColor: "default",
            },
          },
        },
      },
    },
  },
});

function Login() {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState({ otp: "", login: "", signup: "" });
  const [errorAlertStatus, setErrorAlertStatus] = useState(false);

  const jwtTokenStatus = sessionStorage.getItem("invalid_jwtToken");

  const loginFields = [
    { name: "E-Mail", initialValues: "loginEmail" },
    { name: "Password", initialValues: "loginPassword" },
  ];

  const LogInSchema = Yup.object().shape({
    loginEmail: Yup.string().email("Must be a valid email").required("Email is required"),
    loginPassword: Yup.string().required("Password is required").min(5, "Minimum 5 character"),
  });

  // check JWT Token validity
  useEffect(() => {
    if (jwtTokenStatus === "invalid") {
      setErrorAlertStatus(true);
    }
    // Set a ErrorAlert to false after 10 sec
    const timer = setTimeout(() => {
      setErrorAlertStatus(false);
      sessionStorage.removeItem("invalid_jwtToken");
    }, 10000); // 10 sec

    // Clear the timer if the component unmounts (optional)
    return () => clearTimeout(timer);
  }, [jwtTokenStatus]);

  const loginFormik = useFormik({
    initialValues: {
      loginEmail: "",
      loginPassword: "",
    },
    onSubmit: async (values) => {
      setErrorAlertStatus(false);
      const data = { user_email: values.loginEmail, user_password: values.loginPassword };
      await axios
        .post(loginApiURL, data)
        .then((response) => {
          if (response.status === 200 && response.data.response_message === "SUCCESS") {
            // sessionStorage.setItem("jwtToken", `${response.data.jwt_token}`);
            // sessionStorage.removeItem("invalid_jwtToken");
            // loginFormik.resetForm({ values: loginFormik.initialValues });
            // navigate("/chat");
            window.location.href = response.data.response_data.redirect_url;
          }
        })
        .catch((err) => {
          // handle error status
          // set error message into useState (same with the above)
          if (err.response.status === 500) {
            setErrorAlertStatus(true);
            return;
          }
          if (err.response.status === 401) {
            setErrorMessage({
              ...errorMessage,
              login: err.response.data.response_message,
            });
            setTimeout(() => {
              setErrorMessage({
                ...errorMessage,
                login: "",
              });
            }, 5000);
          }
        });
    },
    validationSchema: LogInSchema,
  });
  return (
    <Grid container>
      <Grid
        item
        md={6}
        lg={6}
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ backgroundColor: "#D7EDFD", height: "100vh" }}>
        <Stack alignItems="center">
          {errorAlertStatus === true && <ErrorAlert jwtToken={jwtTokenStatus} />}
          <Typography
            className="product-name"
            sx={{
              background: "linear-gradient(271deg, #0432B9 -39.8%, #0C1D49 158.29%)",
              backgroundClip: "text",
              fontSize: "5rem",
              fontWeight: "700",
              fontStyle: "normal",
              lineHeight: "normal",
            }}>
            GenInsight.ai
          </Typography>
          <Stack direction="row" gap={1} alignItems="center" justifyContent="center">
            <Typography sx={{ fontSize: "1.6rem", fontWeight: "700", fontStyle: "normal", lineHeight: "normal" }}>
              Powered By
            </Typography>
            <img src={Corestrat_logo} alt="Corestrat Logo" style={{ width: "12rem" }}></img>
          </Stack>
        </Stack>
      </Grid>
      <Grid
        item
        md={6}
        lg={6}
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ background: "radial-gradient(162.23% 113.78% at 99.07% 1.87%, #0C1D49 5.16%, #0432B9 95.52%)" }}>
        <Stack sx={{ width: "60%" }} justifyContent="center" alignItems="center" rowGap={2}>
          {/* <img src={ACI_logo} alt="ACI Logo" style={{ width: "20rem" }}></img> */}
          <Typography
            sx={{
              margin: "0 auto",
              fontSize: "2rem",
              color: "#ffffff",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "normal",
              marginBottom: "20px",
              textAlign: "center",
            }}>
            Login to your account
          </Typography>
          <form onSubmit={loginFormik.handleSubmit}>
            <Stack rowGap={3}>
              <Stack sx={{ position: "relative" }} rowGap={4}>
                <span style={{ color: "#faff00", textAlign: "center", position: "absolute", top: "-30px", left: "155px" }}>
                  {errorMessage.login !== "" && errorMessage.login}
                </span>
                {loginFields.map((item, idx) => {
                  return (
                    <>
                      <TextField
                        variant="outlined"
                        fullWidth
                        key={idx}
                        id={`outlined-${idx}`}
                        name={`${item.initialValues}`}
                        type={item.name.includes("Password") ? "password" : "text"}
                        label={`${item.name}`}
                        value={loginFormik.values[item.initialValues]}
                        onChange={loginFormik.handleChange}
                        // className="myTheme"
                        sx={{
                          "& .MuiOutlinedInput-input": {
                            color: "#ffffff",
                            padding: "16.5px 14px 19.5px 14px",
                            "&:-webkit-autofill": {
                              // WebkitBoxShadow: "0 0 0 30px inherit inset",
                              WebkitTextFillColor: "#ffffff",
                            },
                          },
                          "& .MuiInputLabel-root": {
                            color: "#ffffff !important",
                            fontSize: "20px",
                            "&.Mui-focused fieldset": {
                              // color: "#ffffff",
                            },
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            // color: "#ffffff",
                            borderColor: "#ffffff",
                            fontSize: "20px",
                          },
                          "& .MuiOutlinedInput-root": {
                            "&:hover fieldset": {
                              borderColor: "#0093fb",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#ffffff",
                            },
                          },
                        }}
                      />
                      {loginFormik.errors[item.initialValues] && loginFormik.touched[item.initialValues] && (
                        <i className={item.initialValues === "loginEmail" ? "error-msg_1" : "error-msg_2"}>
                          {loginFormik.errors[item.initialValues]}
                        </i>
                      )}
                    </>
                  );
                })}
              </Stack>
              <Stack direction="row" display="flex" justifyContent="space-between" sx={{ width: "30rem" }}>
                <Typography sx={{ color: "#ffffff" }}>
                  Don’t have an account yet?{" "}
                  <Typography
                    variant="span"
                    sx={{
                      ":hover": {
                        textDecoration: "underline",
                        cursor: "pointer",
                      },
                    }}
                    onClick={() => {
                      navigate("/signup");
                      setErrorMessage({
                        ...errorMessage,
                        login: "",
                      });
                    }}>
                    Sign up
                  </Typography>
                </Typography>
                <Typography
                  variant="span"
                  sx={{
                    color: "#ffffff",
                    ":hover": {
                      textDecoration: "underline",
                      cursor: "pointer",
                    },
                  }}
                  onClick={() => {
                    navigate("/forget-password");
                    setErrorMessage({
                      ...errorMessage,
                      login: "",
                    });
                  }}>
                  Forget Password?
                </Typography>
              </Stack>
              <Button type="submit" variant="contained" sx={{ width: "10rem", margin: "0 auto", borderRadius: "50px" }}>
                Login
              </Button>
            </Stack>
          </form>
        </Stack>
      </Grid>
    </Grid>
  );
}

export default Login;

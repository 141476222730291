import "./access.css";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import Corestrat_logo from "../../assets/Corestrat.svg";
import ACI_logo from "../../assets/ACI_logo.svg";
import ErrorAlert from "../../components/errorAlert";

import { Button, Grid, Stack, Typography } from "@mui/material";

function ForgetPassword() {
  const navigate = useNavigate();
  //   const [errorMessage, setErrorMessage] = useState({ otp: "", login: "", signup: "" });
  const [errorAlertStatus, setErrorAlertStatus] = useState(false);

  return (
    <Grid container>
      <Grid
        item
        md={6}
        lg={6}
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ backgroundColor: "#D7EDFD", height: "100vh" }}>
        <Stack alignItems="center">
          {errorAlertStatus === true && <ErrorAlert />}
          <Typography
            className="product-name"
            sx={{
              background: "linear-gradient(271deg, #0432B9 -39.8%, #0C1D49 158.29%)",
              backgroundClip: "text",
              fontSize: "5rem",
              fontWeight: "700",
              fontStyle: "normal",
              lineHeight: "normal",
            }}>
            GenInsight.ai
          </Typography>
          <Stack direction="row" gap={1} alignItems="center" justifyContent="center">
            <Typography sx={{ fontSize: "1.6rem", fontWeight: "700", fontStyle: "normal", lineHeight: "normal" }}>
              Powered By
            </Typography>
            <img src={Corestrat_logo} alt="Corestrat Logo" style={{ width: "12rem" }}></img>
          </Stack>
        </Stack>
      </Grid>
      <Grid
        item
        md={6}
        lg={6}
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ background: "radial-gradient(162.23% 113.78% at 99.07% 1.87%, #0C1D49 5.16%, #0432B9 95.52%)" }}>
        <Stack sx={{ width: "60%" }} justifyContent="center" alignItems="center" rowGap={2}>
          {/* <img src={ACI_logo} alt="ACI Logo" style={{ width: "20rem" }}></img> */}
          <Typography
            sx={{
              margin: "0 auto",
              fontSize: "2rem",
              color: "#ffffff",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "normal",
              marginBottom: "20px",
              textAlign: "center",
            }}>
            Reset your password
          </Typography>
          <Button
            type="submit"
            variant="contained"
            sx={{ width: "10rem", margin: "0 auto", borderRadius: "50px" }}
            onClick={() => {
              navigate("/login");
            }}>
            go back
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
}

export default ForgetPassword;

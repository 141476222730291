import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

function Auth(){
    const navigate = useNavigate();   
    const { jwt_token } = useParams();
    
    useEffect(() => {
        sessionStorage.setItem("jwtToken",  jwt_token)
        navigate("/chat");
    }, []); 

    return (
        <div></div>
    )
}

export default Auth;

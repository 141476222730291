import "./access.css";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { MuiOtpInput } from "mui-one-time-password-input";

import Corestrat_logo from "../../assets/Corestrat.svg";
import ACI_logo from "../../assets/ACI_logo.svg";
import ErrorAlert from "../../components/errorAlert";
import Timer from "../../components/timer";

import { Button, Grid, Stack, Typography } from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";

const signupApiURL = `${process.env.REACT_APP_BACKEND_URL}api/v1/user/signup`;
const generateOtpApiURL = `${process.env.REACT_APP_BACKEND_URL}api/v1/user/generateOtp`;
const validateOtpApiURL = `${process.env.REACT_APP_BACKEND_URL}api/v1/user/validateOtp`;

function Otp() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { user_uuid, user_name, user_email, user_password } = state;
  const [errorMessage, setErrorMessage] = useState({ otp: "", login: "", signup: "" });
  const [errorAlertStatus, setErrorAlertStatus] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpDisabled, setOtpDisabled] = useState(true);
  const [otpStatus, setOtpStatus] = useState("OTP");
  const [counter, setCounter] = useState(3);
  const [uuid, setuuid] = useState(user_uuid);

  const handleOTPChange = (newValue) => {
    setOtp(newValue);
    setOtpDisabled(true);
  };

  function matchIsNumeric(text) {
    const isNumber = typeof text === "number";
    return isNumber || !isNaN(Number(text));
  }

  const validateChar = (value, index) => {
    return matchIsNumeric(value);
  };

  const handleOTPComplete = () => {
    setOtpDisabled(false);
  };

  const handleOTPVerification = async () => {
    setErrorAlertStatus(false);
    const data = { temp_user_id: uuid, user_otp: otp };
    await axios
      .post(validateOtpApiURL, data)
      .then(async (response) => {
        if (response.data.response_message === "SUCCESS") {
          const data = {
            user_name: user_name,
            user_email: user_email,
            user_password: user_password,
          };
          await axios
            .post(signupApiURL, data)
            .then((response) => {
              if (response.data.response_message === "CREATED") {
                setOtpStatus("Success");
                setErrorMessage({
                  ...errorMessage,
                  otp: "",
                });
                return;
              }
            })
            .catch((err) => {
              if (err.response.status === 500) {
                setErrorAlertStatus(true);
                return;
              }
            });
        }
      })
      .catch((err) => {
        if (err.response.status === 500) {
          setErrorAlertStatus(true);
          return;
        }
        if (err.response.status === 401) {
          let tempCounter = counter - 1;
          if (tempCounter <= 0) {
            setOtpStatus("Failed");
            setErrorMessage({
              ...errorMessage,
              otp: `The OTP you entered is invalid. Please enter the correct OTP (${tempCounter} more tries left)`,
            });
            return;
          }
          setCounter(tempCounter);
          setOtp("");
          setErrorMessage({
            ...errorMessage,
            otp: `The OTP you entered is invalid. Please enter the correct OTP (${tempCounter} more tries left)`,
          });
        }
      });
  };

  //----------------- Timer --------------------------------------
  const timerForOTP = new Date();
  timerForOTP.setSeconds(timerForOTP.getSeconds() + 120); // 2 minutes timer

  const handleResendOTP = async () => {
    setErrorAlertStatus(false);
    setCounter(3);
    setErrorMessage({
      ...errorMessage,
      otp: "",
    });
    const data = {
      temp_user_id: uuidv4(),
      user_email: user_email,
      user_name: user_name,
    };
    await axios
      .post(generateOtpApiURL, data)
      .then((response) => {
        if (response.status === 201 && response.data.response_message === "CREATED") {
          console.log("Success generate otp");
          setuuid(data.temp_user_id);
          return;
        }
      })
      .catch((err) => {
        if (err.response.status === 500) {
          setErrorAlertStatus(true);
          return;
        }
      });
  };
  return (
    <Grid container>
      <Grid
        item
        md={6}
        lg={6}
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ backgroundColor: "#D7EDFD", height: "100vh" }}>
        <Stack alignItems="center">
          {errorAlertStatus === true && <ErrorAlert />}
          <Typography
            className="product-name"
            sx={{
              background: "linear-gradient(271deg, #0432B9 -39.8%, #0C1D49 158.29%)",
              backgroundClip: "text",
              fontSize: "5rem",
              fontWeight: "700",
              fontStyle: "normal",
              lineHeight: "normal",
            }}>
            GenInsight.ai
          </Typography>
          <Stack direction="row" gap={1} alignItems="center" justifyContent="center">
            <Typography sx={{ fontSize: "1.6rem", fontWeight: "700", fontStyle: "normal", lineHeight: "normal" }}>
              Powered By
            </Typography>
            <img src={Corestrat_logo} alt="Corestrat Logo" style={{ width: "12rem" }}></img>
          </Stack>
        </Stack>
      </Grid>
      <Grid
        item
        md={6}
        lg={6}
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ background: "radial-gradient(162.23% 113.78% at 99.07% 1.87%, #0C1D49 5.16%, #0432B9 95.52%)" }}>
        <Stack sx={{ width: "60%" }} justifyContent="center" alignItems="center" rowGap={2}>
          {/* <img src={ACI_logo} alt="ACI Logo" style={{ width: "20rem" }}></img> */}
          <Typography
            sx={{
              margin: "0 auto",
              fontSize: "2rem",
              color: "#ffffff",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "normal",
              marginBottom: "20px",
              textAlign: "center",
            }}>
            E-Mail OTP Verification
          </Typography>
          {otpStatus === "OTP" && (
            <Stack rowGap={4.5} sx={{ position: "relative" }}>
              <Typography sx={{ color: "#ffffff", textAlign: "center" }} justifyContent="center" alignItems="center">
                Please enter the 6 digit verification code that was sent,
                <p>the code is valid for 10 minutes.</p>
              </Typography>
              <MuiOtpInput
                autoFocus
                value={otp}
                length={6}
                onChange={handleOTPChange}
                validateChar={validateChar}
                onComplete={handleOTPComplete}
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": { borderColor: "#ffffff" },
                  "& .MuiOutlinedInput-root": {
                    color: "#ffffff",
                  },
                }}
              />
              {errorMessage.otp !== "" && <i className="error-msg_otp">{errorMessage.otp}</i>}
              <Stack direction="row" justifyContent="center" alignItems="center" columnGap={3} sx={{ marginTop: "30px" }}>
                <Timer expiryTimestamp={timerForOTP} handleResendOTP={handleResendOTP} />
                <Button
                  variant="contained"
                  sx={{
                    width: "10rem",
                    borderRadius: "50px",
                    "&:disabled": {
                      backgroundColor: "#999999",
                    },
                  }}
                  disabled={otpDisabled}
                  onClick={() => {
                    handleOTPVerification();
                  }}>
                  Verify
                </Button>
              </Stack>
            </Stack>
          )}
          {otpStatus === "Success" && (
            <>
              <CheckCircleOutlineOutlinedIcon sx={{ fontSize: "140px", color: "#20C997", margin: "0 auto" }} />
              <Button
                variant="contained"
                sx={{ width: "10rem", margin: "0 auto", borderRadius: "50px" }}
                onClick={() => {
                  navigate("/login");
                }}>
                Login
              </Button>
            </>
          )}
          {otpStatus === "Failed" && (
            <>
              <CancelOutlinedIcon sx={{ fontSize: "140px", color: "#D32F2F", margin: "0 auto" }} />
              <Button
                variant="contained"
                sx={{ width: "10rem", margin: "0 auto", borderRadius: "50px" }}
                onClick={() => {
                  navigate("/signup");
                }}>
                Re-Signup
              </Button>
            </>
          )}
        </Stack>
      </Grid>
    </Grid>
  );
}

export default Otp;

import React, { useState } from "react";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const getRequestAccess = `${process.env.REACT_APP_BACKEND_URL}api/v1/user/requestAccess`;

function ErrorAlert(props) {
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();
  let jwtToken = { Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}` };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    sessionStorage.removeItem("invalid_jwtToken");
    setOpen(false);
  };

  const handleClick = async () => {
    const headers = jwtToken;
    await axios
      .get(getRequestAccess, {
        withCredentials: false,
        headers,
      })
      .then((response) => {
        if (response.status === 200 && response.data.response_message === "SUCCESS") {
          // console.log(response);
          props.requestAccess(true);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.code === "ERR_NETWORK" || err.response.status === 500) {
          // setErrorAlert(true);
          return;
        }
        if (err.response.status === 401) {
          sessionStorage.removeItem("jwtToken");
          sessionStorage.setItem("invalid_jwtToken", "invalid");
          navigate("/login");
          return;
        }
        if (err.response.status === 400) {
          console.log(err);
          return;
        }
      });
  };
  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Snackbar open={open} autoHideDuration={10000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {props.requestAccessError ? (
            <>
              <Typography>{props.errorMessage.split(".")[0] + "."}</Typography>
              <Typography>{props.errorMessage.split(".")[1]}</Typography>
              <Typography
                sx={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={() => {
                  handleClick();
                }}
              >
                Request Access.
              </Typography>
            </>
          ) : jwtToken === "invalid" ? (
            "Session Expired, You have been logged out"
          ) : (
            "Internal Server Error, Please Try again."
          )}
          {/* {jwtToken === "invalid" ? "Session Expired, You have been logged out" : "Internal Server Error, Please Try again."} */}
        </Alert>
      </Snackbar>
    </Stack>
  );
}

export default ErrorAlert;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.product-name {
  background-clip: "text";
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.error-msg_1 {
  position: absolute;
  top: 60px;
  color: #faff00;
}

.error-msg_2 {
  position: absolute;
  top: 9.438rem;
  color: #faff00;
}

.error-msg_3 {
  position: absolute;
  top: 242px;
  color: #faff00;
}

.error-msg_4 {
  position: absolute;
  top: 332px;
  color: #faff00;
}

.error-msg_otp {
  position: absolute;
  top: calc(100% - 91px);
  color: #faff00;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition: background-color 21600s ease-in-out 0s;
  transition: background-color 21600s ease-in-out 0s;
}
`, "",{"version":3,"sources":["webpack://./src/pages/access/access.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,6BAA6B;EAC7B,oCAAoC;AACtC;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,sBAAsB;EACtB,cAAc;AAChB;;AAEA;;;;EAIE,0DAAkD;EAAlD,kDAAkD;AACpD","sourcesContent":[".product-name {\n  background-clip: \"text\";\n  -webkit-background-clip: text;\n  -webkit-text-fill-color: transparent;\n}\n\n.error-msg_1 {\n  position: absolute;\n  top: 60px;\n  color: #faff00;\n}\n\n.error-msg_2 {\n  position: absolute;\n  top: 9.438rem;\n  color: #faff00;\n}\n\n.error-msg_3 {\n  position: absolute;\n  top: 242px;\n  color: #faff00;\n}\n\n.error-msg_4 {\n  position: absolute;\n  top: 332px;\n  color: #faff00;\n}\n\n.error-msg_otp {\n  position: absolute;\n  top: calc(100% - 91px);\n  color: #faff00;\n}\n\ninput:-webkit-autofill,\ninput:-webkit-autofill:hover,\ninput:-webkit-autofill:focus,\ninput:-webkit-autofill:active {\n  transition: background-color 21600s ease-in-out 0s;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useEffect, useState } from "react";
import { useTimer } from "react-timer-hook";
import { Button } from "@mui/material";

function Timer({ expiryTimestamp, handleResendOTP }) {
  // totalSeconds, seconds, minutes, hours, days, isRunning, start, pause, resume, restart
  const { seconds, minutes, restart } = useTimer({
    expiryTimestamp,
  });
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    // Set a timer to disable the button after 2 mins
    const timer = setTimeout(() => {
      setDisabled(false);
    }, 120000); // 120000 milliseconds = 2 mins

    // Clear the timer if the component unmounts (optional)
    return () => clearTimeout(timer);
  }, [disabled]);

  return (
    <div style={{ textAlign: "center" }}>
      <div>
        {disabled ? (
          <>
            <Button
              variant="contained"
              sx={{
                textAlign: "center",
                padding: "4px 8px",
                width: "10rem",
                margin: "0 auto",
                borderRadius: "50px",
                "&:disabled": {
                  backgroundColor: "#999999",
                  color: "#ffffff",
                },
              }}
              disabled={disabled}>
              {minutes}:{seconds}
            </Button>
          </>
        ) : (
          <Button
            variant="contained"
            sx={{
              width: "10rem",
              margin: "0 auto",
              borderRadius: "50px",
            }}
            onClick={() => {
              handleResendOTP(true);
              setDisabled(true);
              const time = new Date();
              time.setSeconds(time.getSeconds() + 120);
              restart(time);
            }}>
            Resend
          </Button>
        )}
      </div>
    </div>
  );
}

export default Timer;

import "./access.css";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

import Corestrat_logo from "../../assets/Corestrat.svg";
import ACI_logo from "../../assets/ACI_logo.svg";
import ErrorAlert from "../../components/errorAlert";

import { Button, Grid, Stack, TextField, Typography } from "@mui/material";

const checkUniqueApiURL = `${process.env.REACT_APP_BACKEND_URL}api/v1/user/checkUnique`;
const generateOtpApiURL = `${process.env.REACT_APP_BACKEND_URL}api/v1/user/generateOtp`;

function Signup() {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState({ otp: "", login: "", signup: "" });
  const [errorAlertStatus, setErrorAlertStatus] = useState(false);

  const signupFields = [
    { name: "Name", initialValues: "signupName" },
    { name: "E-Mail", initialValues: "signupEMail" },
    { name: "Password", initialValues: "signupPassword" },
    { name: "Confirm Password", initialValues: "signupConfirmPassword" },
  ];

  const SignUpSchema = Yup.object().shape({
    signupName: Yup.string().min(2, "Minimum 2 character").max(50, "Too Long!").required("Name is required"),
    signupEMail: Yup.string().email("Invalid E-Mail").required("Email is required"),
    signupPassword: Yup.string().required("Password is required").min(5, "Minimum 5 character"),
    signupConfirmPassword: Yup.string().oneOf([Yup.ref("signupPassword"), null], "Passwords must match"),
  });
  const signupFormik = useFormik({
    initialValues: {
      signupName: "",
      signupEMail: "",
      signupPassword: "",
      signupConfirmPassword: "",
    },
    onSubmit: async (values) => {
      setErrorAlertStatus(false);
      const data = { user_name: values.signupName, user_email: values.signupEMail, user_password: values.signupConfirmPassword };
      await axios
        .post(checkUniqueApiURL, data)
        .then(async (response) => {
          console.log(response);
          if (response.status === 200 && response.data.response_message === "SUCCESS") {
            const data = {
              temp_user_id: uuidv4(),
              user_email: values.signupEMail,
              user_name: values.signupName,
            };
            await axios.post(generateOtpApiURL, data).then((response) => {
              if (response.status === 201 && response.data.response_message === "CREATED") {
                navigate("/signup/otp", {
                  state: {
                    user_uuid: data.temp_user_id,
                    user_name: values.signupName,
                    user_email: values.signupEMail,
                    user_password: values.signupConfirmPassword,
                  },
                });

                signupFormik.resetForm({ values: signupFormik.initialValues });
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 500) {
            setErrorAlertStatus(true);
            return;
          }
          if (err.response.status === 409) {
            setErrorMessage({
              ...errorMessage,
              signup: "E-Mail already exists, Please sign up with another E-Mail",
            });
          }
        });
    },
    validationSchema: SignUpSchema,
  });
  return (
    <Grid container>
      <Grid
        item
        md={6}
        lg={6}
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ backgroundColor: "#D7EDFD", height: "100vh" }}>
        <Stack alignItems="center">
          {errorAlertStatus === true && <ErrorAlert />}
          <Typography
            className="product-name"
            sx={{
              background: "linear-gradient(271deg, #0432B9 -39.8%, #0C1D49 158.29%)",
              backgroundClip: "text",
              fontSize: "5rem",
              fontWeight: "700",
              fontStyle: "normal",
              lineHeight: "normal",
            }}>
            GenInsight.ai
          </Typography>
          <Stack direction="row" gap={1} alignItems="center" justifyContent="center">
            <Typography sx={{ fontSize: "1.6rem", fontWeight: "700", fontStyle: "normal", lineHeight: "normal" }}>
              Powered By
            </Typography>
            <img src={Corestrat_logo} alt="Corestrat Logo" style={{ width: "12rem" }}></img>
          </Stack>
        </Stack>
      </Grid>
      <Grid
        item
        md={6}
        lg={6}
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ background: "radial-gradient(162.23% 113.78% at 99.07% 1.87%, #0C1D49 5.16%, #0432B9 95.52%)" }}>
        <Stack sx={{ width: "60%" }} justifyContent="center" alignItems="center" rowGap={2}>
          {/* <img src={ACI_logo} alt="ACI Logo" style={{ width: "20rem" }}></img> */}
          <Typography
            sx={{
              margin: "0 auto",
              fontSize: "2rem",
              color: "#ffffff",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "normal",
              marginBottom: "20px",
              textAlign: "center",
            }}>
            Create Account
          </Typography>
          <form onSubmit={signupFormik.handleSubmit}>
            <Stack rowGap={3} sx={{ position: "relative" }}>
              {errorMessage.signup !== "" && (
                <i style={{ color: "#faff00", position: "absolute", left: "34px", top: "-30px" }}>{errorMessage.signup}</i>
              )}
              <Stack sx={{ position: "relative" }} rowGap={4}>
                {signupFields.map((item, idx) => {
                  return (
                    <>
                      <TextField
                        fullWidth
                        key={idx}
                        variant="outlined"
                        id={`outlined-${idx}`}
                        name={`${item.initialValues}`}
                        type={item.name.includes("Password") ? "password" : "text"}
                        label={`${item.name}`}
                        value={signupFormik.values[item.initialValues]}
                        onChange={signupFormik.handleChange}
                        sx={{
                          "& .MuiOutlinedInput-input": {
                            color: "#ffffff",
                            padding: "16.5px 14px 19.5px 14px",
                            "&:-webkit-autofill": {
                              // WebkitBoxShadow: "0 0 0 30px inherit inset",
                              WebkitTextFillColor: "#ffffff",
                            },
                          },
                          "& .MuiInputLabel-root": {
                            color: "#ffffff !important",
                            fontSize: "20px",
                            "&.Mui-focused fieldset": {
                              color: "#ffffff",
                            },
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            color: "#ffffff",
                            borderColor: "#ffffff",
                            fontSize: "20px",
                          },
                          "& .MuiOutlinedInput-root": {
                            "&:hover fieldset": {
                              borderColor: "#0093fb",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#ffffff",
                            },
                          },
                        }}
                      />
                      {signupFormik.errors[item.initialValues] && signupFormik.touched[item.initialValues] && (
                        <i
                          className={
                            item.initialValues === "signupName"
                              ? "error-msg_1"
                              : item.initialValues === "signupEMail"
                              ? "error-msg_2"
                              : item.initialValues === "signupPassword"
                              ? "error-msg_3"
                              : "error-msg_4"
                          }>
                          {signupFormik.errors[item.initialValues]}
                        </i>
                      )}
                    </>
                  );
                })}
              </Stack>
              <Stack direction="row" display="flex" justifyContent="space-between" sx={{ width: "30rem" }}>
                <Typography sx={{ color: "#ffffff" }}>
                  Have an account?{" "}
                  <Typography
                    variant="span"
                    sx={{
                      ":hover": {
                        textDecoration: "underline",
                        cursor: "pointer",
                      },
                    }}
                    onClick={() => {
                      navigate("/login");
                      setErrorMessage({
                        ...errorMessage,
                        singup: "",
                      });
                    }}>
                    Log In
                  </Typography>
                </Typography>
              </Stack>
              <Button variant="contained" type="submit" sx={{ width: "10rem", margin: "0 auto", borderRadius: "50px" }}>
                Sign Up
              </Button>
            </Stack>
          </form>
        </Stack>
      </Grid>
    </Grid>
  );
}

export default Signup;
